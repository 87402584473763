import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import ImageAlbumIcon from 'mdi-react/ImageOutlineIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');

export const IconButtonType = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  ADD_USER: 'ADD_USER',
  IMAGE: 'IMAGE',
  VIEW: 'VIEW',
};
const CustomIcon = ({ type }) => {
  switch (type) {
    case IconButtonType.EDIT: return <EditOutlineIcon />;
    case IconButtonType.DELETE: return <DeleteOutlineIcon />;
    case IconButtonType.IMAGE: return <ImageAlbumIcon />;
    case IconButtonType.VIEW: return <EyeIcon />;
    default: return null;
  }
};
const getTitle = (type) => {
  switch (type) {
    case IconButtonType.EDIT: return s('edit');
    case IconButtonType.DELETE: return s('delete');
    case IconButtonType.VIEW: return s('show');
    default: return '';
  }
};
const IconButton = ({ onClick, type, title, id }) => {
  const buttonTitle = title || getTitle(type);
  return (
    <Button
      outline
      id={id}
      onClick={onClick}
    >
      <span>
        <CustomIcon type={type} />
        <UncontrolledTooltip placement="bottom" target={id}>
          {buttonTitle}
        </UncontrolledTooltip>
      </span>
    </Button>
  );
};

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys(IconButtonType)).isRequired,
  title: PropTypes.string,
};

IconButton.defaultProps = {
  title: null,
};
IconButton.types = IconButtonType;
export default IconButton;
