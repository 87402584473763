import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/en-gb';
import { useRecoilState } from 'recoil';
import { setCompanies, setIsAuthenticated } from '../redux/actions/userActions';
import { setEmployeeProfile } from '../redux/actions/employeeActions';
import Layout from '../_layout/index';
import MainWrapper from './MainWrapper';
import LoginPage from '../log_in/LoginPage';
import EmailLoginPage from '../email_login/EmailLoginPage';
import EmployeeListPage from '../employee/EmployeeListPage';
import CompanyProfilePage from '../profile/CompanyProfilePage';
import CompanyProfileEitPage from '../profile/CompanyProfileEditPage';
import EmployeeProfileEitPage from '../employee/EmployeeProfileEditPage';
import ClubListPage from '../clubs/ClubListPage';
import OfferListPage from '../offers/OfferListPage';
import SelectCompanyPage from './SelectCompanyPage';
import AddEmployeePage from '../employee/AddEmployeePage';
import AuthService from './AuthService';
import userLanguageState from '../../recoil/selector/selector';
import './firebase';
import Api from '../util/api';
import RestorePasswordPage from '../restore_password/RestorePasswordPage';
import storage from '../util/storage';
import SponsorMaterialPage from '../sponsor/SponsorMaterialPage';
import CompanyClubFormPage from '../CompanyClubFormPage';
import OfferFormPage from '../offers/OfferFormPage';

const wrappedRoutes = () => {
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          <Route path="/employee/:employeeID" component={EmployeeProfileEitPage} />
          <Route path="/employees" component={EmployeeListPage} />
          <Route path="/addEmployee" component={AddEmployeePage} />
          <Route path="/profile/:companyID" component={CompanyProfileEitPage} />
          <Route path="/profile" component={CompanyProfilePage} />
          <Route path="/sponsorMaterial/:companyID" component={SponsorMaterialPage} />
          <Route path="/clubs/edit/:clubID" component={CompanyClubFormPage} />
          <Route path="/clubs" component={ClubListPage} />
          <Route path="/offers/edit/:offerID" component={OfferFormPage} />
          <Route path="/offers/create" component={OfferFormPage} />
          <Route path="/offers" component={OfferListPage} />
          <Route exact path="/" component={ClubListPage} />
        </Switch>
      </div>
    </div>
  );
};

const Router = ({ isAuthenticated, dispatch }) => {
  const history = useHistory();
  const [lang] = useRecoilState(userLanguageState);
  const fetchCustomClaims = async () => {
    const auth = getAuth();
    const currFirToken = await auth.currentUser.getIdToken();
    const { token: customToken } = await Api.authorization.getCustomToken(currFirToken);
    await signInWithCustomToken(auth, customToken);
  };

  async function getCompanies() {
    try {
      const resEmployees = await Api.employees.getAllEmployeeProfiles();
      const uniqueCompanies = [...new Set(resEmployees.map((employeeProfile) => employeeProfile.company).map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
      const company = storage.getCurrentCompany();
      if (company) {
        let selectedEmployee;
        resEmployees.forEach((employeeProfile) => {
          if (employeeProfile?.company?.id === company.id) {
            selectedEmployee = employeeProfile;
          }
        });
        dispatch(setCompanies(uniqueCompanies));
        dispatch(setEmployeeProfile(selectedEmployee));
        dispatch(setIsAuthenticated(true));
      } else if (resEmployees.length === 1) {
        const selectedEmployee = resEmployees[0];
        storage.setCurrentCompany(uniqueCompanies[0]);
        dispatch(setCompanies(uniqueCompanies));
        dispatch(setEmployeeProfile(selectedEmployee));
        dispatch(setIsAuthenticated(true));
        history.replace('/');
      } else {
        history.replace('/select_company');
        dispatch(setIsAuthenticated(false));
      }
    } catch (err) {
      AuthService.signout(dispatch);
      history.replace('/log_in');
    }
  }
  const onAuthStateChanged = async (user) => {
    if (user) {
      try {
        await fetchCustomClaims();
        await AuthService.afterFirebaseLogin(dispatch);
        await getCompanies();
      } catch (e) {
        toast.error('Failed to log in');
        history.push('/log_in');
      }
    } else {
      dispatch(setIsAuthenticated(false));
      if (!window.location.pathname.includes('restore_password')) {
        history.replace('/log_in');
      }
    }
  };
  useEffect(() => {
    if (isAuthenticated === false && ['/select_company'].includes(history.location.pathname)) {
      history.replace('/select_company');
    } else if (isAuthenticated === false && !window.location.pathname.includes('restore_password')) {
      history.replace('/log_in');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(onAuthStateChanged);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    moment.locale(lang);
  }, [lang]);

  if (isAuthenticated === 'loading') {
    return <p style={{ background: '#ffffff' }}>Loading...</p>;
  }
  return (
    <MainWrapper>
      <main>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        {isAuthenticated === true ? (
          <Switch>
            <Route path="/" component={wrappedRoutes} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/log_in" component={LoginPage} />
            <Route exact path="/email_login" component={EmailLoginPage} />
            <Route exact path="/select_company" component={SelectCompanyPage} />
            <Route exact path="/restore_password/:identity/:token" component={RestorePasswordPage} />
          </Switch>
        )}
      </main>
    </MainWrapper>
  );
};

Router.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default connect((state) => ({
  isAuthenticated: state.auth.isAuthenticated,
}))(Router);
