import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import OfferCard from './components/OfferCard';
import { OfferState } from './context';
import { partial } from '../util/translation/translation';


const OffersListPage = () => {
  const f = partial('OffersPage');
  const fp = partial('OffersFormPage');

    return (
      <Container>
        <Row>
          <Col>
            <p className="page-title">{f('offers')}</p>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Link to="/offers/create">
              <Button size="sm" color="primary">{fp('createOffer')}</Button>
            </Link>
          </Col>
        </Row>
        <OfferState>
          <OfferCard status="active" />
          <OfferCard status="expired" />
        </OfferState>
      </Container>
    );
};
export default OffersListPage;
