import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import InformationIcon from 'mdi-react/InformationIcon';
import ShareModal from './ShareModal';

const TooltipWithIcon = ({
  size, text, isImageUrl, fullImageUrl, placement, id,
}) => {
  const [toggleTooltip, setToggleTooltip] = useState(false);
  const [showFullImage, setShowFullImage] = useState(false);

  const handleClick = () => {
    setShowFullImage(isImageUrl || showFullImage);
  };

  return (
    <>
      <InformationIcon onClick={() => handleClick()} id={id} size={size} />
      <Tooltip placement={placement} target={id} isOpen={toggleTooltip} toggle={() => setToggleTooltip(!toggleTooltip)} innerClassName="tooltip-black">
        {isImageUrl ?
          <img src={text} alt="" height={200} />
          :
          text
        }
      </Tooltip>

      {fullImageUrl?.length > 0 && (
        <ShareModal
          visible={showFullImage}
          handleModal={() => setShowFullImage(false)}
          size="sm"
        >
          <img src={fullImageUrl} alt="" />
        </ShareModal>
      )}
    </>
  );
};

TooltipWithIcon.defaultProps = {
  fullImageUrl: '',
  isImageUrl: false,
  size: 18,
  placement: 'right',
};

TooltipWithIcon.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isImageUrl: PropTypes.bool,
  fullImageUrl: PropTypes.string,
  size: PropTypes.number,
  placement: PropTypes.string,
};

export default TooltipWithIcon;
