import React, { useState, useEffect, memo, useCallback, useContext } from 'react';
import moment from 'moment/moment';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row, Card, CardBody, Table, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Api from '../../util/api';
import utilities from '../utilities';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import { OfferContext } from '../context';
import Pagination from '../../shared/components/pagination/Pagination';
import useConfig from '../../shared/hooks/useConfig';
import { partial } from '../../util/translation/translation';
import ClubInfo from './ClubInfo';

const OfferCard = ({ status }) => {
  const s = partial('shared');
  const f = partial('OffersFormPage');
  const c = partial('OfferCard');
  const { fetchAllOffers, filterOffersByCategory, deleteOfferById } = Api.offers;
  const { truncate } = utilities;
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [id] = useConfig();
  const [handleModal, visible, deleteID] = useModal();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { categories } = useContext(OfferContext);
  const [collapseCategory, setCollapseCategory] = useState(false);
  const [categoriesWithFilter, setCategoriesWithFilter] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({ first: 1, last: 1, previous: null, next: null, total: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState([10, 20, 30]);
  const [pageSize, setPageSize] = useState(rowsPerPage[0]);

  const toggleCategory = () => {
    setCollapseCategory(!collapseCategory);
  };

  const getAllOffers = useCallback(async () => {
    const results = await fetchAllOffers(id, currentPage, pageSize, status);
    setOffers(results.offers);
    setFilteredOffers(results.offers);
    setPaginationDetails({ first: results.first, last: results.last, previous: results.previous, next: results.next, total: results.total });
  }, [fetchAllOffers, id, currentPage, pageSize, status]);

  useEffect(() => {
    getAllOffers();
  }, [getAllOffers]);

  useEffect(() => {
    if (categories.length > 0) {
      setCategoriesWithFilter(categories);
    }
  }, [categories]);

  const fromatCategory = (data) => {
    if (data.includes('&')) {
      return data.split('&').map(word => word.trim().toLowerCase()).join().replace(',', '_');
    }
    return data.toLowerCase().replace(' ', '_');
  };

  const renderTable = (offer) =>
    <tr key={offer.id}>
      <td><Link to={`/offers/edit/${offer.id}`}>{offer.title}</Link></td>
      <td>{truncate(50, offer.description)}</td>
      <td>{c(fromatCategory(offer.category))}</td>
      <td>{moment(offer.startDate).format('yyyy/MM/DD')}</td>
      <td>{moment(offer.endDate).format('yyyy/MM/DD')}</td>
      <td><ClubInfo clubIds={offer.clubIds}/></td>
      <td>
        <div className="flex">
          <div className="action-btn">
            <EditIcon size={14}/>
            <Link className="ml-1" to={`/offers/edit/${offer.id}`}>{s('edit')}</Link>
          </div>
          <div className="ml-3 action-btn">
            <DeleteIcon size={14} color="red" />
            <button className="ml-1 action-btn__danger" onClick={() => handleModal(offer.id)}>{s('delete')}</button>
          </div>
        </div>
      </td>
    </tr>;

  const handleFilterOffers = (category) => {
    setSelectedCategory(category.label);
    filterOffersByCategory(category.id, id, currentPage, pageSize, status).then(response => {
      setFilteredOffers(response.offers);
    });
    toggleCategory();
  };

  const handleSearchOffers = (e) => {
    setSearchQuery(e.target.value);
    setFilteredOffers(offers.filter(o => o.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1));
  };

  const deleteOffer = async () => {
    try {
      await deleteOfferById(deleteID, id);
      fetchAllOffers(id, currentPage, pageSize, status).then(response => {
        setOffers(response.offers);
        setFilteredOffers(response.offers);
        setPaginationDetails({ first: response.first, last: response.last, previous: response.previous, next: response.next, total: response.total });
      });
      toast.success(c('offerHasBeenDeleted'));
      handleModal();
    } catch (err) {
      toast.error(s('somethingWentWrong'));
    }
  };

  const handlePagination = (paginationObject) => {
    setCurrentPage(paginationObject.currentPage);
    if (pageSize !== paginationObject.pageSize) {
      setPageSize(paginationObject.pageSize);
    }
  };

  if (offers?.length === 0) {
    return (<div/>);
  }

  return (
    <Row>
      <DeleteModal
        type="offer"
        visible={visible}
        handleModal={handleModal}
        modalAction={deleteOffer}
      />
      <Col>
        <Card>
          <CardBody>
            <div className="flex justify-content-between">
              <h5>{status === 'active' ? f('activeOffers') : f('expiredOffers')}</h5>
              <div className="flex">
                <div className="form mr-3">
                  <div className="form__form-group products-list__search">
                    <input
                      value={searchQuery}
                      placeholder={s('search')}
                      onChange={handleSearchOffers}
                    />
                  </div>
                </div>
                <UncontrolledDropdown>
                  <DropdownToggle className="icon icon--right" size="sm" outline>{selectedCategory === '' ? `${c('filterByCategory')}` : selectedCategory} <ChevronDownIcon/></DropdownToggle>
                  <DropdownMenu className="dropdown__menu">
                    {categoriesWithFilter.map((category) =>
                      <DropdownItem
                        key={category.value}
                        onClick={() => handleFilterOffers(category)}
                      >
                        {c(category.key)}
                      </DropdownItem>)}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
            {filteredOffers?.length > 0 ?
              <Table responsive bordered className="mt-3">
                <thead>
                  <tr>
                    <th>{s('title')}</th>
                    <th>{s('description')}</th>
                    <th>{s('category')}</th>
                    <th>{s('startDate')}</th>
                    <th>{s('endDate')}</th>
                    <th>{s('club')}</th>
                    <th>{s('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOffers.map(offer => renderTable(offer))}
                </tbody>
              </Table> :
              <p className="offer-table">{c('noRecords')}</p>
            }
          </CardBody>
          <Pagination items={paginationDetails.total || 0} rowsPerPage={rowsPerPage} onChangePage={handlePagination} />
        </Card>
      </Col>
    </Row>
  );
};
export default withRouter(memo(OfferCard));
