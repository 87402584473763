import React from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import UpIcon from 'mdi-react/ChevronUpIcon';

const TeamRow = ({ visible, team = {}, onPress }) => {
  const SelectedIcon = visible ? UpIcon : DownIcon;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img style={{ height: '48px', width: 'auto', marginRight: '2px' }} src={team?.imageUrl} alt="logo" />
        <div className="table-title marginLeft-8">{team.name}</div>
      </div>
      <button onClick={() => onPress(team.clubId)} className="topbar__avatar">
        <SelectedIcon className="topbar__icon height-50" size={40}/>
      </button>
    </div>
  );
};


export default TeamRow;

