import React from 'react';

const InitialsImage = ({ name, imageUrl, wrapperClass = '' }) => {
    if (imageUrl) {
        return (
          <img src={imageUrl} className={`initials-image ${wrapperClass}`} alt="logo" />
        );
    }
    return (
      <div className={`initials-image ${wrapperClass}`}>
        <p>{name}</p>
      </div>
    );
};


export default InitialsImage;
