export default {
  getAuthToken: () => localStorage.getItem('authToken'),
  setAuthToken: token => localStorage.setItem('authToken', token),
  removeAuthToken: () => localStorage.removeItem('authToken'),
  setCurrentCompany: (company) => localStorage.setItem('currentCompany', JSON.stringify(company)),
  getCurrentCompany: () => JSON.parse(localStorage.getItem('currentCompany')),
  removeCurrentCompany: () => localStorage.removeItem('currentCompany'),
  // setCurrentFirebaseTeam: (name) => localStorage.setItem('currentFirebaseTeam', name || club),
  // removeCurrentFirebaseTeam: () => localStorage.removeItem('currentFirebaseTeam'),
  // getCurrentFirebaseTeam: () => localStorage.getItem('currentFirebaseTeam'),
};
