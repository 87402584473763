import t, { partial } from '../util/translation/translation';

const p = partial('validateErrors');

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = p('emailFieldCantBeEmpty');
  }
  if (!values.countryCode) {
    errors.countryCode = p('contryCodeFieldEmpty');
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = p('phoneNumberFieldEmpty');
  }
  if (!values.club) {
    errors.club = t('OfferForm.clubRequired');
  }
  return errors;
};
export default validate;
