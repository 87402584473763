import countries from 'i18n-iso-countries';
import { getServiceUrl } from './api';
import { partial } from './translation/translation';
// import config from '../config';
countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};
export const convertPositionToPositionSignature = (position) => {
  const p = partial('PlayerForm');
  switch (position) {
    case 'Keeper': return p('goalKeeperShort');
    case 'Back': return p('backShort');
    case 'Attacker': return p('attackerShort');
    case 'Right wing': return p('rightWingShort');
    case 'Left wing': return p('leftWingShort');
    default: return '';
  }
};
export const convertScreenToTranslation = (screenName) => {
  const p = partial('Reports');
  const removedSeasonAndTicket = screenName.replace('Season', '').replace('Ticket', '').replace('ticket', '').trim();
  switch (removedSeasonAndTicket) {
    case 'entrance': return p('ticketEntrance');
    case 'pick zone': return p('ticketPickZone');
    case 'pick row': return p('ticketPickRow');
    case 'pick seat': return p('ticketPickSeat');
    case 'confirmation': return p('ticketConfirmation');
    case 'Receipt': return p('receipt');
    default: return '';
  }
};

export const formatCurrencyNumber = (number = 0) => (Number(number).toLocaleString().split(',').join(' '));

export const convertLayoutName = (layoutName) => {
  const p = partial('AppLayout');
  try {
    return p(`${layoutName}Tile`);
  } catch (e) {
    return p('missingName');
  }
};

export const range = function (_start, _end, _step) {
  const step = _step === undefined ? 1 : _step;
  let end = _end;
  let start = _start;
  const arr = [];
  let len = 0;
  let i = 0;
  if (arguments.length === 1) {
    len = start;
    start = 0;
    end = start;
  } else {
    start = start === undefined ? 1 : start;
    end = end === undefined ? 1 : end;
    len = end - start;
  }
  while (i < len) {
    arr.push(start + i * step);
    i += 1;
  }
  return arr;
};
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const isInfiniteDate = (inputDate) => {
  return inputDate === new Date(Date.UTC(10000, 0, 1)).toISOString();
};

export const isFirebaseAvailable = () => {
  return false;
};
export const isSportalityAvailable = () => {
  return false;
};
export const isSportalityLeagueAvailable = () => {
  return false;
};
export const cleanUrlParams = (imageUrl) => imageUrl?.split('?')[0];
export const getCountryList = (userLanguage) => Object.entries(countries.getNames(userLanguage)).map(([iso, country]) => ({
  name: country,
  iso,
  value: iso,
  label: country,
}));
export const getImageHash = (imageUrl) => {
  const baseImageUrl = `${getServiceUrl('image')}/`;
  const replaced = imageUrl.replace(baseImageUrl, '');
  const finalHash = replaced.includes('?') ? replaced.split('?')[0] : replaced;
  return finalHash;
};
export const constructImageData = (dataObject, fieldName, itemName) => dataObject[fieldName]
? {
    name: itemName,
    preview: dataObject[fieldName],
    hash: getImageHash(dataObject[fieldName]),
  }
: null;
export const uploadOrRemoveImage = async (enityType, values, fieldName, companyId, clubId) => {
  const upload = (await values[fieldName]?.promise?.(enityType, fieldName, companyId, clubId) || values[fieldName]?.preview?.includes('blob'));
  if (!upload && !values[fieldName]?.preview) {
   await values[fieldName]?.remove?.(enityType, fieldName, companyId, values[fieldName]?.hash, clubId);
  }
};
