import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export class MultiSelectField extends PureComponent {
  handleChange = (value) => {
    this.props.onChange(value);
  };

  render() {
    const {
      value, name, placeholder, options,
      disabled,
    } = this.props;

    return (
      <Select
        multi
        disabled={disabled}
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className="form__form-group-select"
        closeOnSelect={false}
        removeSelected={false}
        placeholder={placeholder}
      />
    );
  }
}

MultiSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  ]).isRequired,
};

MultiSelectField.defaultProps = {
  placeholder: '',
  options: [],
};
