import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import validateOneTimeCode from './validateOneTimeCode';
import renderField from '../../shared/components/custom/Field';
import { partial } from '../../util/translation/translation';

const p = partial('ForgotPasswordEnterNewPass');

const ForgotPasswordEnterNewPass = ({ handleSubmit }) => {
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{p('enterOneTimeCode')}</span>
        <div className="form__form-group-field">
          <Field
            name="token"
            component={renderField}
            type="string"
            placeholder={p('oneTimeCode')}
          />
        </div>
        <span className="form__form-group-label">{p('enterPassword')}</span>
        <div className="form__form-group-field">
          <Field
            name="password"
            component={renderField}
            type="password"
            placeholder={p('password')}
          />
        </div>
        <span className="form__form-group-label">{p('reEnterPassword')}</span>
        <div className="form__form-group-field">
          <Field
            name="passwordConfirm"
            component={renderField}
            type="password"
            placeholder={p('password')}
          />
        </div>
      </div>
      <Button color="primary" className="width-100p">
        {p('restorePassword')}
      </Button>
    </form>
  );
};

ForgotPasswordEnterNewPass.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'one_time_code_form',
  validateOneTimeCode,
});

export default reduxF(ForgotPasswordEnterNewPass);
