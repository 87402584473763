import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setEmployeeProfile } from '../redux/actions/employeeActions';
import { setCompanies, setIsAuthenticated } from '../redux/actions/userActions';
import SelectCompanyForm from './SelectCompanyForm';
import { partial } from '../util/translation/translation';
import storage from '../util/storage';
import AuthService from './AuthService';
import Api from '../util/api';

const p = partial('SelectCompanyPage');
const SelectCompanyPage = ({ dispatch }) => {
  const [employeeProfiles, setEmployeeProfiles] = useState();
  const [companies, setCompaniesState] = useState();
  const history = useHistory();

  async function getEmployeeProfiles() {
    try {
      const resEmployees = await Api.employees.getAllEmployeeProfiles();
      const uniqueCompanies = [...new Set(resEmployees.map((employeeProfile) => employeeProfile.company).map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
      if (!uniqueCompanies.length) {
        toast.error(p('notEmployee'), { autoClose: 8000 });
        AuthService.signout(dispatch);
        history.replace('/log_in');
      } else {
        setEmployeeProfiles(resEmployees);
        setCompaniesState(uniqueCompanies);
        dispatch(setCompanies(uniqueCompanies));
      }
    } catch (err) {
      AuthService.signout(dispatch);
      history.replace('/log_in');
    }
  }

  const handleCompanySelect = (selectedCompany) => {
    if (selectedCompany !== undefined) {
      const selectedEmployee = employeeProfiles.find((item) => item.company.id === selectedCompany.id);
      storage.setCurrentCompany(selectedCompany);
      dispatch(setEmployeeProfile(selectedEmployee));
      dispatch(setIsAuthenticated(true));
      history.replace('/');
    } else {
      toast.info(p('selectCompanyWarning'));
    }
  };

  useEffect(() => {
    getEmployeeProfiles();
  }, []);

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">{p('welcomeTo')} Score</h3>
          </div>
          <p className="company_select_header">{p('selectCompany')}</p>
          <SelectCompanyForm companies={companies} handleSubmit={handleCompanySelect} />
        </div>
      </div>
    </div>
  );
};

export default connect()(SelectCompanyPage);
