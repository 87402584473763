import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, TabPane, TabContent, Col } from 'reactstrap';
import { useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../shared/components/custom/Loading';
import Api from '../util/api';
import { partial } from '../util/translation/translation';
import storage from '../util/storage';
import NavTab from '../shared/components/custom/NavTab';
import CompanyClubDetailsForm from './CompanyClubDetailsForm';
import SponsorMaterialForm from './SponsorMaterialForm';
import { uploadOrRemoveImage } from '../util/functions';

function CompanyClubFormPage({ history }) {
  const s = partial('shared');
  const p = partial('CompanyClubFormPage');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [companyDetails, setCompanyDetails] = useState(null);
  const { clubID } = useParams();
  const companyID = storage.getCurrentCompany().id;
  const getCompanyClubDetails = async () => {
    setLoading(true);
    try {
      const companyClubDetails = await Api.company.getCompany(companyID, clubID);
      setCompanyDetails(companyClubDetails);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(p('clubFetchError'));
    }
  };
  const goBack = () => history.replace('/clubs');
  useEffect(() => {
    getCompanyClubDetails();
  }, [clubID]);
  const handleDetailsSubmit = async (values) => {
    const {
      phone,
      countryCode,
      country,
      ...restValues
    } = values;
    const payload = {
      ...restValues,
      country: country?.name,
      phone: `+${countryCode}${phone}`,
    };
    setLoading(true);
    try {
      await Api.company.editCompany(payload, companyID, clubID);
      setLoading(false);
      toast.success(p('successfulyUpdatedCompany'));
      goBack();
    } catch (err) {
      setLoading(false);
      toast.error(p('companyUpdateError'));
    }
  };
  const handleSponsorMaterialSubmit = async (values) => {
    setLoading(true);
    const IMAGE_FIELDS = [
      'companyImage',
      'appSponsorImage',
      'coverImage',
      'appTicketImage',
      'printImage',
      'playerImage',
    ];
    try {
      IMAGE_FIELDS.forEach((field) => uploadOrRemoveImage('company', values, field, companyID, clubID));
      setLoading(false);
      toast.success(p('successfulyUpdatedCompany'));
      goBack();
    } catch (err) {
      toast.error(p('companyUpdateError'));
      setLoading(false);
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <p className="page-title">{p('editClubSpecifics')}</p>
      </Col>
      <Card>
        <CardBody>
          <NavTab
            tabs={[
              { tab: p('details') },
              { tab: s('sponsorMaterial') },
            ]}
            setTab={setActiveTab}
            activeTab={activeTab}
            inline
          />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <CompanyClubDetailsForm onSubmit={handleDetailsSubmit} details={companyDetails} goBack={goBack}/>
            </TabPane>
            <TabPane tabId="2">
              <SponsorMaterialForm onSubmit={handleSponsorMaterialSubmit} images={companyDetails?.images} goBack={goBack}/>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Container>
  );
}
export default withRouter(CompanyClubFormPage);

