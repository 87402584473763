import { partial } from '../util/translation/translation';

const p = partial('validateErrors');

const validate = (values) => {
  const errors = {};
  if (!values.orgName) {
    errors.orgName = `${p('companyNameFieldEmpty')}`;
  }
  if (!values.orgNumber) {
    errors.orgNumber = `${p('organizationNumberFieldEmpty')}`;
  }
  if (!values.countryCode) {
    errors.phone = `${p('contryCodeFieldEmpty')}`;
  }
  if (!values.phoneNumber) {
    errors.phone = `${p('phoneNumberFieldEmpty')}`;
  }
  if (!values.category) {
    errors.category = `${p('categoryFieldEmpty')}`;
  }

  return errors;
};

export default validate;
