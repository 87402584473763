import React, { PureComponent } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TooltipWithIcon from '../custom/TooltipWithIcon';

class CheckBoxField extends PureComponent {
  componentDidMount() {
    if (this.props.disableDefault === false) {
      this.props.onChange(this.props.defaultChecked);
    }
  }

  render() {
    const { disabled, className, name, value, onChange, label, color, tooltip } = this.props;
    const CheckboxClass = classNames({
      'checkbox-btn': true,
      disabled,
    });

    return (
      <label className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`} htmlFor={name}>
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        <span className="checkbox-btn__checkbox-custom" style={color ? { background: color, borderColor: color } : {}}>
          <CheckIcon />
        </span>
        {className === 'button' ? (
          <span className="checkbox-btn__label-svg">
            <CheckIcon className="checkbox-btn__label-check" />
            <CloseIcon className="checkbox-btn__label-uncheck" />
          </span>
        ) : (
          ''
        )}
        <span className="checkbox-btn__label">{label}</span>{' '}
        {tooltip && (
          <span style={{ marginLeft: '10px' }}>
            <TooltipWithIcon id={name} text={tooltip} />
          </span>
        )}
      </label>
    );
  }
}

CheckBoxField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disableDefault: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
};
CheckBoxField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  className: '',
  color: '',
  tooltip: '',
};

const renderCheckBoxField = (props) => (
  <CheckBoxField
    {...props.input}
    label={props.label}
    defaultChecked={props.defaultChecked}
    disabled={props.disabled}
    disableDefault={props.disableDefault}
    className={props.className}
    color={props.color}
    tooltip={props.tooltip}
  />
);

renderCheckBoxField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disableDefault: PropTypes.bool,
  tooltip: PropTypes.string,
};

renderCheckBoxField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  className: '',
  color: '',
  disableDefault: false,
  tooltip: '',
};

export default renderCheckBoxField;
