import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { partial } from '../util/translation/translation';

const s = partial('shared');
const SelectCompanyForm = ({ handleSubmit, companies }) => {
  const [active, setActive] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(undefined);

  const handleCompanySelect = (companyItem) => {
    setActive(!active);
    setSelectedCompany(!active ? companyItem : undefined);
  };
  return (
    <div className="form">
      <Col className="company_select_card_wrapper">
        {companies &&
          companies.map((item) => {
            return (
              item && (
                <Card key={`company_select_${item.id}`} className="company_select_main_wrapper">
                  <CardBody
                    className={`company_select_wrapper${active && selectedCompany.id === item.id ? '_active' : ''}`}
                    onClick={() => handleCompanySelect(item)}
                  >
                    <div className="company_select_inner_wrapper">
                      <div className="company__avatar_img">
                        <div className="company__avatar-icon">{item.generalName?.[0]?.toUpperCase() ?? ''}</div>
                      </div>
                      <div className="company__select_name">{item.generalName}</div>
                    </div>
                  </CardBody>
                </Card>
              )
            );
          })}
      </Col>

      <Button color="primary" className="width-100p" onClick={() => handleSubmit(selectedCompany)}>
        {s('next')}
      </Button>
    </div>
  );
};

SelectCompanyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default SelectCompanyForm;
