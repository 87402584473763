import React from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';

const Loading = (props) => {
  return (
    <div>
      {props.loading ? (
        <div className="loading-full">
          <LoadingIcon size="48" color="#42e2b5" />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loading;
