import { memo, useMemo } from 'react';
import { connect } from 'react-redux';

const ClubInfo = ({ clubIds, clubsData }) => {
    const name = useMemo(() =>
        clubsData.clubs.filter((item) => clubIds.includes(item.clubId)).reduce((tot, curr) => `${tot} ${curr.shortName}`, ''),
        [clubIds, clubsData],
    );
    return [name];
};
export default connect((state) => ({
    clubsData: state.clubs,
  }))(memo(ClubInfo));
