import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import validateLogin from './validateLogin';
import renderField from '../../shared/components/custom/Field';
import t, { partial } from '../../util/translation/translation';

const p = partial('EmailLogInForm');
const ForgotButton = ({ onClick }) => {
  return (
    <div
      className="form__form-group-field mb-30 pointerButton"
      role="button"
      tabIndex={0}
      onClick={onClick}
      style={{ justifyContent: 'flex-end' }}
    >
      <p className="login__form_link">{t('LogInForm.forgotPassword')}</p>
    </div>
  );
};
const EmailLogInForm = ({ handleSubmit, loading, goToPassword, goToForgot }) => {
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group marginBottom-5 mt-30">
        <div id="recaptcha-container" />
        <span className="form__form-group-label loginpages-title">
          {t('LogInForm.emailLogin')}
        </span>
        <div className="form__form-group-field">
          <Field name="email" component={renderField} placeholder={t('EmailLogInForm.email')} autoComplete="email" />
        </div>
        <div className="form__form-group-field">
          <Field
            name="password"
            component={renderField}
            type="password"
            placeholder={t('shared.password')}
            autoComplete="current-password"
          />
        </div>
      </div>
      <ForgotButton onClick={goToForgot}/>
      <Button color="primary" className="width-100p margin-0 bold-text" disabled={loading}>
        {t('LogInForm.logIn')}
      </Button>
      <p className="account__or">
        <span>{p('or')}</span>
      </p>
      <Button color="secondary" className="width-100p bold-text" outline disabled={loading} onClick={goToPassword}>
        {p('goToSms')}
      </Button>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'log_in_form',
  validateLogin,
});

export default reduxF(EmailLogInForm);
