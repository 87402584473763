import React from 'react';
import LinkIcon from 'mdi-react/LinkVariantIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerRadiusIcon';
import t, { partial } from '../util/translation/translation';
import ShareModal from '../shared/components/custom/ShareModal';
import InitialsImage from '../shared/components/custom/InitialsImage';

const s = partial('shared');
const p = partial('ClubListPage');
const EmployeeTile = ({ data }) => {
    const fullName = `${data.firstName} ${data.lastName}`;
    const initials = `${data.firstName.slice(0, 1).toUpperCase()}${data.lastName.slice(0, 1).toUpperCase()}`;
    return (
      <div className="alignItems-center flex-row marginVertical-10">
        <InitialsImage
          name={initials}
          imageUrl={data?.profileImage}
          wrapperClass="employee-profile-image"
        />
        <div className="paddingHorizontal-10 flex-Hcenter flex-column">
          <span style={{ fontSize: 15, fontWeight: 'bold' }}>{fullName}</span>
          <span style={{ fontSize: 15 }}>{data.positionTitle}</span>
        </div>
      </div>
    );
};
function CompanyProfileModal({ details, handleModal }) {
    const companyCategories = details?.companyCategories?.map((category) => {
      let translatedName;
      try {
        translatedName = t(`Category.${category}`);
      } catch (err) {
        translatedName = category;
      }
      return translatedName;
    }).join(', ') || '';

    if (!details) {
        return null;
    }
    const openLink = (link) => {
      const win = window.open(`${link.includes('http') ? link : `https://${link}`}`, '_blank');
      win.focus();
    };
  return (
    <ShareModal
      boldHeader
      visible={!!details}
      title={p('previewCompanyProfile')}
      handleModal={handleModal}
    >
      <div>
        <InitialsImage
          name={details?.name}
          imageUrl={details?.images?.coverImage}
        />
        <div className="flex-row flex-HSpaceBetween marginTop-20">
          <div>
            <p style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1, color: 'black' }}>{details.name}</p>
            <p style={{ lineHeight: 0.5 }}>{companyCategories}</p>
            <p style={{ lineHeight: 0.5 }}>{details?.address || ''} {details?.zip || ''} {details?.city || ''}</p>
          </div>
          <div className="activeUserStatsRowContainer">
            <button className="mapLink marginRight-15" onClick={() => openLink(details?.websiteUrl)}>
              <LinkIcon color="#6c6c6c"/>
            </button>
            <button className="mapLink" onClick={() => openLink(details?.addressLink)}>
              <MapMarkerIcon color="#6c6c6c"/>
            </button>
          </div>
        </div>
        <div className="marginTop-30">
          <strong>{s('description').toUpperCase()}</strong>
          <p>
            {details.companyStrenghts}
          </p>
        </div>
        <div className="marginTop-30">
          <strong className="bold">{t('companyProfile.whyWeSupport').toUpperCase()} {details.clubShortName}</strong>
          <p>
            {details.companySupportDescription}
          </p>
        </div>
        <div className="marginTop-30">
          <strong>{p('companyRepresentativesForClub').toUpperCase()} {details.clubShortName}</strong>
          <div style={{ maxHeight: 300, overflow: 'scroll' }}>
            {details.employees.map((employee) => <EmployeeTile data={employee} key={employee.id}/>)}
          </div>
        </div>

      </div>
    </ShareModal>
  );
}
export default CompanyProfileModal;

