import React, { useState } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import userLanguageState from '../../recoil/selector/selector';
import FindUserForm from './FindUserForm';
import AddEmployeeForm from './AddEmployeeForm';
import Api from '../util/api';
import Loading from '../shared/components/custom/Loading';
import { partial } from '../util/translation/translation';

const s = partial('shared');
const p = partial('Employee');
const v = partial('validateErrors');
const AddEmployeePage = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUserData] = useState(undefined);
  const [searchType, setSearchType] = useState('phone');
  const [lang] = useRecoilState(userLanguageState);
  const history = useHistory();

  const uploadImage = async (image, employeeID) => {
    const hasImage = Array.isArray(image) && image.length === 1;
    if (!hasImage) {
      return;
    }
    try {
      const data = new FormData();
      data.append('image', image[0]);
      data.append('type', 'employee');
      data.append('uploadEntityField', 'sqNormal');
      data.append('uploadEntityId', `${employeeID}`);
      await Api.employees.uploadEmployeeImage(data);
    } catch (err) {
      toast.error(err || `${s('imageUploadingERROR')}`);
      throw err;
    }
  };
  const handleEmployeeSubmit = async (values) => {
    const { firstName, lastName, positionTitle, countryCode, phoneNumber, email, userId, visibleCompany, visiblePrivate, club } = values;
    const phone = `+${countryCode}${phoneNumber}`;
    setLoading(true);
    try {
      const payload = {
        firstName,
        lastName,
        positionTitle,
        phone,
        email,
        lang,
        visibleCompany,
        visiblePrivate,
        userId,
        clubId: club.value.clubId,
        sendMessage: true,
        clubShortName: club.value.shortName,
        clubName: club.value.name,
        clubLogo: club.value.imageUrl,
      };

      const result = await Api.employees.createEmployee(payload);
      if (values.image && values.image[0] instanceof File) {
        await uploadImage(values.image, result.id);
      }
      toast.success(p('employeeCreated'));
      history.replace('/employees');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('employeeSavingERROR')}'!'`);
    }
  };
  const handleSearch = async (values) => {
    const initialInput = values[searchType];
    const isPhoneSearch = searchType === 'phone';
    const inputValue = isPhoneSearch ? initialInput.phone : initialInput;
    if (!inputValue) {
      toast.info(v('fieldEmpty'));
      return;
    }
    const condition = {
      [isPhoneSearch ? 'phoneNumber' : 'email']: inputValue,
    };
    try {
      const userData = await Api.users.userSearch(condition);
      if (!userData) {
        toast.info(p('noResultFound'));
        setUserData({});
        return;
      }
      setUserData(userData);
    } catch (err) {
      toast.error(err || p('noResultFound'));
      setUserData({});
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <div>
        <h3 className="page-title">
          {s('create')} {s('employee')}
        </h3>
      </div>
      <Card>
        <CardBody>
          <FindUserForm onSubmit={handleSearch} setSearchType={setSearchType} searchType={searchType}/>
          <AddEmployeeForm type="create" onSubmit={handleEmployeeSubmit} user={user} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default AddEmployeePage;
