import { partial } from '../util/translation/translation';

const p = partial('validateErrors');

const validateUsername = ({ email, phone }) => {
  const errors = {};
  if (!email) {
    errors.email = `${p('emailFieldCantBeEmpty')}`;
  }
  if (!phone) {
    errors.phone = `${p('phoneFieldCantBeEmpty')}`;
  }
  return errors;
};

export default validateUsername;
