import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '../redux/actions/sidebarActions';
import {
  changeThemeToDark,
  changeThemeToLight,
} from '../redux/actions/themeActions';
import { SidebarProps } from '../shared/prop-types/ReducerProps';

const Layout = (props) => {
  const _changeSidebarVisibility = () => {
    props.dispatch(changeSidebarVisibility());
  };

  const _changeMobileSidebarVisibility = () => {
    props.dispatch(changeMobileSidebarVisibility());
  };

  const _changeToLight = () => {
    props.dispatch(changeThemeToLight);
  };
  const _changeToDark = () => {
    props.dispatch(changeThemeToDark);
  };
  return (
    <div>
      <Topbar
        changeMobileSidebarVisibility={_changeMobileSidebarVisibility}
        changeSidebarVisibility={_changeSidebarVisibility}
      />
      <Sidebar
        sidebar={props.sidebar}
        changeMobileSidebarVisibility={_changeMobileSidebarVisibility}
        changeToLight={_changeToLight}
        changeToDark={_changeToDark}
      />
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
};

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
    clubs: state.clubs,
  }))(Layout),
);
