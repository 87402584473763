import { partial } from '../util/translation/translation';

const p = partial('validateErrors');
const validateDetails = (values) => {
  const errors = {};
  if (!values.phone && !values.phoneNumber) {
    errors.phoneNumber = `${p('phoneNumberFieldEmpty')}`;
    errors.phone = `${p('phoneNumberFieldEmpty')}`;
  }
  if (!values.email) {
    errors.email = `${p('emailFieldCantBeEmpty')}`;
  }
  return errors;
};

export default validateDetails;
