import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Col, Button, Row } from 'reactstrap';
import countries from 'i18n-iso-countries';
import { parsePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import companyValidate from './companyValidate';
import renderField from '../shared/components/custom/Field';
import renderMultiSelectField from '../shared/components/form/RenderMultiSelectField';
import renderSelectField from '../shared/components/form/Select';
import userLanguageState from '../../recoil/selector/selector';
import { partial } from '../util/translation/translation';
import Api from '../util/api';

const s = partial('shared');
const p = partial('CompanyForm');
const ct = partial('Category');

countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const CompanyProfileForm = ({ company, handleSubmit, initialize, type }) => {
  const history = useHistory();
  const userLanguage = useRecoilValue(userLanguageState);
  const countryOptions = Object.entries(countries.getNames(userLanguage)).map(([iso, country]) => ({
    name: country,
    iso,
    value: iso,
    label: country,
  }));
  let countryCode;
  let phoneNumber;
  try {
    const parsedPhone = parsePhoneNumber(company.phone);
    countryCode = parsedPhone.countryCallingCode;
    phoneNumber = parsedPhone.nationalNumber;
  } catch (err) {
    countryCode = undefined;
    phoneNumber = undefined;
  }
  const c = company ? company.country : 'Norway';
  const selectedCountry = countryOptions.find((country) => country.name === c);
  const selectedCategory = company.companyCategories?.map((item) => {
    return {
      name: ct(item),
      label: ct(item),
      value: item,
    };
  });
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const resCategories = await Api.company.getCompanyCategories();
      const formattedCategories = resCategories.map((category) => {
        return {
          name: ct(category),
          value: category,
          label: ct(category),
        };
      });
      setCategories(formattedCategories);
    } catch (err) {
      toast.error(err || `${p('categoryFetchingERROR')}'!'`);
    }
  };

  useEffect(() => {
    if (company?.id) {
      initialize({
        website: company.websiteUrl,
        isSponsor: company.isSponsor,
        orgName: company.name,
        orgNumber: company.organization_number,
        email: company.email,
        category: selectedCategory,
        id: company.id,
        phone: company.phone,
        countryCode,
        phoneNumber,
        description: company.companyStrenghts,
        descriptionSupportingClub: company.companySupportDescription,
        address: company.address,
        city: company.city,
        zip: company.zip,
        country: selectedCountry,
      });
    }
  }, [company]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <form className="form wizard__form" onSubmit={handleSubmit}>
      <Col md={6}>
        <Row>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('name')}*</span>
              <Field name="orgName" component={renderField} placeholder={s('company')} />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('organizationNumber')}*</span>
              <Field name="orgNumber" component={renderField} placeholder="12345678" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('companyEmail')}</span>
              <Field name="email" component={renderField} placeholder={p('companyEmail')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <p className="form-header">{s('countryCode')}</p>
              <Field
                name="countryCode"
                component={renderField}
                type="number"
                placeholder={s('countryCodePlaceHolder')}
              />
            </div>
          </Col>
          <Col lg={8} md={12}>
            <div className="form__form-group">
              <p className="form-header">{p('phoneNumber')}</p>
              <Field name="phoneNumber" component={renderField} type="text" placeholder="12345678" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('website')}</span>
              <Field name="website" component={renderField} placeholder={s('website')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('address')}</span>
              <Field name="address" component={renderField} placeholder={s('address')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('zip')}</span>
              <Field name="zip" component={renderField} placeholder="1234" />
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('city')}</span>
              <Field name="city" component={renderField} placeholder="City" />
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('country')}</span>
              <Field
                name="country"
                component={renderSelectField}
                options={countryOptions}
                placeholder={s('chooseCountry')}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        <Row>
          <Col lg={12}>
            <div className="form__form-group">
              <span className="form-header">{p('companyCategory')}*</span>
              <Field
                name="category"
                options={categories}
                component={renderMultiSelectField}
                placeholder={p('companyCategory')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('companyDescription')}</span>
              <Field name="description" component="textarea" type="textarea" placeholder={p('companyDescription')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('descriptionSupportingClub')}</span>
              <Field
                name="descriptionSupportingClub"
                component="textarea"
                type="textarea"
                placeholder={p('descriptionSupportingClub')}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col>
        <Button color="primary" type="submit" className="next">
          {type === 'create' ? `${s('next')}` : `${s('save')}`}
        </Button>
        <Button className="color-unset" type="button" onClick={() => history.replace('/profile')}>
          {s('close')}
        </Button>
      </Col>
    </form>
  );
};

CompanyProfileForm.propTypes = {
  initialize: PropTypes.func.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    organization_number: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    companyCategory: PropTypes.string,
    isSponsor: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
};

CompanyProfileForm.defaultProps = {
  company: undefined,
  type: 'create',
};

const reduxF = reduxForm({
  form: 'companyProfileForm',
  validate: companyValidate,
});

export default reduxF(CompanyProfileForm);
