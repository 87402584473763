import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopbarMenuLink from './TopbarMenuLink';
import AuthService from '../../_app/AuthService';
import { partial } from '../../util/translation/translation';

const s = partial('shared');
const TopbarProfile = ({ dispatch, employee }) => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  const logOut = () => {
    AuthService.signout(dispatch);
  };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" onClick={toggle}>
        <div className="topbar__avatar-icon">{`${employee?.firstName?.[0]?.toUpperCase() ?? ''}${
          employee?.lastName?.[0]?.toUpperCase() ?? ''
        }`}
        </div>
        <p className="topbar__avatar-name">
          {employee?.firstName} {employee?.lastName}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && <button className="topbar__back" onClick={toggle} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title={s('logOut')} icon="exit" path="/log_in" action={logOut} />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  me: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

TopbarProfile.defaultProps = {
  me: null,
};

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
    me: state.auth.me,
    employee: state.employee.employeeProfile,
  }))(TopbarProfile),
);
