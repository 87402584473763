import React from 'react';
import PropTypes from 'prop-types';
import InputEmoji from 'react-input-emoji';

const renderField = ({
  input: inputProps,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
  autoComplete,
  showError,
  onKeyPress,
  emoji,
}) => {
  const isEmoji = emoji && type === 'text';

  return (
    <div className="form__form-group-input-wrap">
      {isEmoji ? (
        <InputEmoji
          {...inputProps}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          autoComplete={autoComplete}
          onKeyPress={onKeyPress !== undefined ? onKeyPress : undefined}
        />
      ) : (
        <input
          {...inputProps}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          autoComplete={autoComplete}
          onKeyPress={onKeyPress !== undefined ? onKeyPress : undefined}
        />
      )}
      {touched && error && showError && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  showError: PropTypes.bool,
  onKeyPress: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  emoji: PropTypes.bool,
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
  autoComplete: '',
  showError: true,
  onKeyPress: undefined,
  emoji: false,
};

export default renderField;
