import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SidebarProps, ThemeProps } from '../shared/prop-types/ReducerProps';

class MainWrapper extends PureComponent {
  render() {
    const { sidebar, theme } = this.props;

    const wrapperClass = classNames({
      wrapper: true,
      'wrapper--full-width': sidebar.collapse,
      'blocks-with-shadow-theme': true,
      'squared-corner-theme': true,
    });

    return (
      <div className={theme.className}>
        <div className={wrapperClass}>{this.props.children}</div>
      </div>
    );
  }
}
MainWrapper.propTypes = {
  sidebar: SidebarProps.isRequired,
  theme: ThemeProps.isRequired,
  children: PropTypes.element.isRequired,
};

export default connect((state) => ({
  theme: state.theme,
  sidebar: state.sidebar,
}))(MainWrapper);
