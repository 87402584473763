import React, { useState, useEffect } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { useRecoilState } from 'recoil';
import userLanguageState from '../../../recoil/selector/selector';
import { getAvailableLanguages } from '../../util/translation/translation';

const TopbarLanguage = () => {
  const [collapse, setCollapse] = useState(false);
  const [langLabel, setLangLabel] = useState();
  const [lang, setUserLanguages] = useRecoilState(userLanguageState);

  const changeLanguage =
    ({ label, value }) =>
    () => {
      setUserLanguages(value);
      setLangLabel(label);
    };

  const toggle = () => {
    setCollapse(!collapse);
  };

  useEffect(() => {
    const result = getAvailableLanguages().find((item) => item.value === lang);
    setLangLabel(result.label);
  }, [lang]);

  return (
    <div className="topbar__collapse topbar__collapse--language">
      <button className="topbar__avatar" onClick={toggle}>
        <p className="topbar__avatar-name">{langLabel || ''}</p>
        <DownIcon className="topbar__icon" />
      </button>
      <Collapse isOpen={collapse} className="languageBar">
        {getAvailableLanguages().map((languageL) => {
          return (
            <button
              key={`topBarLanguage-${languageL.value}`}
              className="topbar__link btn-noStyle"
              onClick={changeLanguage(languageL)}
            >
              {languageL.label}
            </button>
          );
        })}
      </Collapse>
    </div>
  );
};

export default TopbarLanguage;
