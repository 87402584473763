import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import validateLogin from './validateLogin';
import renderField from '../../shared/components/custom/Field';
import t from '../../util/translation/translation';

const ResetPasswordForm = ({ handleSubmit, loading, goBack }) => {
  return (
    <>
      <p>{t('LoginPage.forgotPasswordMessage')}</p>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group my-30">
          <span className="form__form-group-label loginpages-title">
            {t('EmailLogInForm.email')}
          </span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={renderField}
              placeholder={t('EmailLogInForm.email')}
              autoComplete="email"
            />
          </div>
        </div>
        <Button color="primary" className="account__btn bold-text" disabled={loading}>
          {t('LoginPage.resetPassword')}
        </Button>
        <Button color="secondary" className="account__btn bold-text" outline disabled={loading} onClick={goBack}>
          {t('LoginPage.goBack')}
        </Button>
      </form>
    </>
  );
};

const reduxF = reduxForm({
  form: 'log_in_form',
  validateLogin,
});

export default reduxF(ResetPasswordForm);
