import { partial } from './translation/translation';

const convertImageToBlob = (image) => {
  if (!image) { return; }
  const reader = new FileReader();
  const p = partial('convertImageToBlob');
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException(p('convertingImageERROR')));
    };
    reader.onloadend = async () => {
      resolve(reader.result);
    };
    if (image instanceof Blob) {
      reader.readAsDataURL(image);
    } else {
      reject(new DOMException(p('wrongImageType')));
    }
  });
};

export default convertImageToBlob;
