import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { partial } from '../../../util/translation/translation';
import ImageCropModal from '../custom/imageCropper';
import convertImageToBlob from '../../../util/convertImageToBlob';
import Api from '../../../util/api';
import { cleanUrlParams } from '../../../util/functions';

const p = partial('dropZoneShare');
const s = partial('shared');
const uploadImage = (image, blob) => {
  const hasImage = !!image;
  if (!hasImage) {
    throw new Error('No image to upload');
  }
  return async (type, uploadEntityField, uploadEntityId, clubId) => {
    const data = new FormData();
    const imageMime = blob.split(';base64')[0].split(':')[1];
    data.append('image', image, {
      filename: image.filename,
      contentType: imageMime,
    });
    data.append('type', type);
    data.append('uploadEntityField', uploadEntityField);
    data.append('uploadEntityId', `${uploadEntityId}`);
    const modificationObject = {
      ...image.crop,
      ...image.scale ? { scale: image.scale } : {},
    };
    if (Object.keys(modificationObject).length) {
      data.append('modificationParams', JSON.stringify(modificationObject));
    }
    Api.images.uploadImage(data, clubId).catch(() => {
      toast.error(s('imageUploadingERROR'));
    });
  };
};
class DropZoneFieldMS extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    crop: PropTypes.any,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
      PropTypes.any,
    ]).isRequired,
    className: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  static defaultProps = {
    className: '',
    customHeight: false,
    meta: null,
    crop: false,
  };

  constructor() {
    super();
    this.state = {
      visible: false,
      imageFile: undefined,
      type: 'new',
    };
  }
  getImage() {
    const selectedImage = Array.isArray(this.props.value) ? this.props.value[0] : this.props.value;
    if (selectedImage && !this.props?.value?.remove) {
      const newImage = { ...selectedImage, preview: cleanUrlParams(selectedImage.preview) };
      return newImage;
    }
    return null;
  }

  async change(cropedFile, originalFile) {
    const base64Image = await convertImageToBlob(originalFile);
    const images = uploadImage(originalFile, base64Image);
    this.props.onChange(({
      promise: images,
      ...originalFile,
      src: originalFile.preview,
      value: originalFile.name,
      file: originalFile,
      croppedImage: cropedFile?.preview,
      base64Image,
    }));
    this.setState({ visible: false });
  }
  removeFile(e) {
    e.preventDefault();
    this.props.onChange({
      remove: Api.images.removeImage,
      hash: this.props?.value?.hash,
    });
  }
  render() {
    const selectedImage = this.getImage();
    const { touched, error } = this.props.meta;
    return (
      <div className="dropzone--container">
        <div className={`dropzone dropzone--single${this.props.customHeight ? ' dropzone--custom-height' : ''} ${this.props.className}`}>
          <Dropzone
            className="dropzone__input"
            accept="image/jpeg, image/png, image/webp, image/gif, video/mp4, video/mov, video/ogg, video/mpeg"
            name={this.props.name}
            multiple={false}
            onDrop={async ([original]) => {
              await this.change(null, original);
              this.setState({ visible: true, type: 'edit' });
            }}
          >
            {!selectedImage &&
            <div className="dropzone__drop-here"><span className="lnr lnr-upload" />{p('dropFilesToUpload')}</div>}
          </Dropzone>
          {selectedImage &&
          <div className="dropzone__img">
            <img src={selectedImage.preview} alt="drop-img" />
            <p className="dropzone__img-name">{selectedImage.name}</p>
            <button
              className="dropzone__img-edit"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ type: 'edit', visible: true });
              }}
            >
              {p('edit')}
            </button>
            <button className="dropzone__img-delete" onClick={(e) => this.removeFile(e)}>
              {p('remove')}
            </button>
          </div>}
          {this.state.visible && (
            <ImageCropModal
              crop={this.props.crop}
              locked={this.props.locked}
              src={this.state.imageFile || selectedImage.preview}
              visible={this.state.visible}
              handleModal={() => this.setState({ visible: false })}
              action={async ([cropedFile, originalFile]) => {
                await this.change(cropedFile, originalFile);
              }}
              type={this.state.type}
            />
          )}
        </div>
        {touched && error && <span className="form__form-group-error">{error}</span>}
      </div>
    );
  }
}

const renderDropZoneFieldMS = props => (
  <DropZoneFieldMS
    {...props.input}
    meta={props.meta}
    className={props.className}
    customHeight={props.customHeight}
    crop={props.crop}
  />
);

renderDropZoneFieldMS.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  customHeight: PropTypes.bool,
  className: PropTypes.string,
  crop: PropTypes.any,
};

renderDropZoneFieldMS.defaultProps = {
  meta: null,
  customHeight: false,
  className: '',
};

export default renderDropZoneFieldMS;
