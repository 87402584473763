import { partial } from '../util/translation/translation';

const offerValidations = (values) => {
  const o = partial('OfferForm');

  const errors = {};

  if (!values.title) {
    errors.title = o('titleRequired');
  }

  if (!values.club) {
    errors.club = o('clubRequired');
  }

  if (!values.description) {
    errors.description = o('descriptionRequired');
  }

  if (!values.category) {
    errors.category = o('categoryRequired');
  }

  if (!values.startDate) {
    errors.startDate = o('startDateRequired');
  }

  if (!values.endDate) {
    errors.endDate = o('endDateRequired');
  }

  if (!values.imageUrl) {
    errors.imageUrl = o('imageRequired');
  }

  return errors;
};

export default offerValidations;
