import React, { useState } from 'react';
import { Button, Input, Modal, FormGroup } from 'reactstrap';
import CloseCircleOutline from 'mdi-react/CloseCircleOutlineIcon';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const p = partial('Employee');
const EmployeeDeleteModal = ({ showModal, handleDelete, setShowModal }) => {
  const [textArea, setTextAreaValue] = useState('');
  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        setShowModal(false);
      }}
      size="md"
    >
      <div className="modal">
        <div className="modal-container" style={{ width: '500px' }}>
          <div className="modal-icon">
            <CloseCircleOutline size="36" color="#ff4861" />
          </div>
          <div className="modal-text">
            <h4>{p('employeeDeleteNote')}</h4>
          </div>
          <FormGroup style={{ width: '80%', marginTop: '5px' }}>
            <Input
              type="textarea"
              name="textarea"
              onChange={(event) => setTextAreaValue(event.target.value)}
              placeholder={p('deletePlaceholder')}
              bsSize="sm"
            />
          </FormGroup>

          <div className="modal-buttons">
            <Button className="marginBottom-0" color="danger" onClick={() => handleDelete(textArea)}>
              {s('delete')}
            </Button>
            <Button
              className="marginBottom-0"
              color="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              {s('close')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeDeleteModal;
