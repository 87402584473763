import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Loading from '../shared/components/custom/Loading';
import Api from '../util/api';
import OfferForm from './components/OfferForm';
import { OfferState } from './context';
import useConfig from '../shared/hooks/useConfig';
import t, { partial } from '../util/translation/translation';
import OfferTypes from './types';
import AlertModal, { AlertModalType } from '../shared/components/custom/AlertModal';

const OfferFormPage = (props) => {
    const o = partial('OffersFormPage');
    const f = partial('OfferForm');
    const { createOffer, updateOfferById } = Api.offers;
    const [id] = useConfig();
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [currentOffer, setCurrentOffer] = useState(null);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getEmployees = useCallback(async () => {
      try {
        const results = await Api.employees.getAllEmployees(id);
        const mappedEmployees = results.map(employee => ({ label: `${employee.firstName} ${employee.lastName}`, value: employee }));
        setEmployees(mappedEmployees);
        setLoading(false);
      } catch (error) {
        toast.error(t('shared.somethingWentWrong'));
        setLoading(false);
      }
    }, [id]);

    useEffect(() => {
      getEmployees();
  }, []);

  const handleSubmit = async (values) => {
    const companyContactPersonId = values?.contactPerson?.value?.id || null;

    let tiersArray = [];
    if (values.tier?.length > 0) {
      tiersArray = values.tier.map(tier => ({ tierId: tier.value, name: tier.name }));
    } else if (values.targetAudience === OfferTypes.TargetAudience.both) {
      tiersArray = [
          {
              tierId: null,
              name: 'all',
          },
      ];
    } else {
      tiersArray = [];
    }


    const payload = {
      title: values.title,
      subtitle: values.subtitle,
      description: values.description,
      clubRelations: [typeof values.club === 'string' ? values.club : values.club.value],
      category: values.category.value,
      price: values.ordinaryPrice ? Number(values.ordinaryPrice) : 0,
      discountedPrice: values.discountedPrice ? Number(values.discountedPrice) : 0,
      discountedPercentage: values.discountedPercentage ? Number(values.discountedPercentage) : 0,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      targetAudience: values.targetAudience,
      isVisible: values.isVisible,
      levels: values.level?.length > 0 ? values.level.map(l => ({ levelId: l.value, name: l.name })) : [],
      tiers: tiersArray,
      companyContactPersonId,
      websiteUrl: values.websiteUrl,
    };
    if (payload.targetAudience === OfferTypes.TargetAudience.partner && payload.levels.length === 0) {
        setErrorMessage(o('levelOrGroupRequiredMessage'));
        setShowPermissionModal(true);
        return;
    }
    if (payload.targetAudience === OfferTypes.TargetAudience.private && payload.tiers.length === 0) {
      setErrorMessage(o('levelOrGroupRequiredMessage'));
      setShowPermissionModal(true);
      return;
    }
    if (!payload.companyContactPersonId && payload.targetAudience === OfferTypes.TargetAudience.partner) {
      setErrorMessage(f('contactRequired'));
      setShowPermissionModal(true);
      return;
    }
    try {
      setLoading(true);
      if (!currentOffer) {
        const data = await createOffer(payload, id);
        await values.imageUrl?.promise('offer', 'imageUrl', data.id);
        toast.success(o(`${'offerHasBeenCreated'}`));
      } else {
        await updateOfferById(currentOffer.id, payload, id);
        await values.imageUrl?.promise?.('offer', 'imageUrl', currentOffer.id);
        toast.success(o(`${'offerHasBeenUpdated'}`));
      }
      setLoading(false);
      props.history.push('/offers');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

   return (
     <Container>
       <AlertModal visible={showPermissionModal} modalType={AlertModalType.ERROR} handleModal={() => setShowPermissionModal(false)} message={errorMessage} />
       <Loading loading={loading} />
       <Row>
         <Col>
           <p className="page-title">{o('createOffer')}</p>
         </Col>
       </Row>
       <Card>
         <CardBody>
           <OfferState>
             <OfferForm
               onSubmit={handleSubmit}
               setCurrentOffer={setCurrentOffer}
               employees={employees}
             />
           </OfferState>
         </CardBody>
       </Card>
     </Container>
   );
};

export default withRouter(OfferFormPage);
