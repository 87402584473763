import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useRecoilValue } from 'recoil';
import renderField from '../shared/components/custom/Field';
import renderSelectField from '../shared/components/form/Select';
import t, { partial } from '../util/translation/translation';
import validateDetails from './validateDetails';
import userLanguageState from '../../recoil/selector/selector';
import { getCountryList } from '../util/functions';
import renderCheckBoxField from '../shared/components/form/CheckBox';
import renderMultiSelectField from '../shared/components/form/RenderMultiSelectField';

function CompanyClubDetailsForm({ handleSubmit, initialize, details, goBack }) {
    const s = partial('shared');
    const p = partial('CompanyForm');
    const ct = partial('Category');

    const userLanguage = useRecoilValue(userLanguageState);
    const countryOptions = getCountryList(userLanguage);
    useEffect(() => {
      if (details) {
        const { isSponsor, companySupportDescription, zip, city, country, address, addressLink, email, companyStrenghts, companyCategories } = details;
        const { countryCallingCode = '', nationalNumber = '' } = parsePhoneNumber(details.phone) || {};
        initialize({
          phone: nationalNumber,
          countryCode: countryCallingCode,
          isSponsor,
          address,
          addressLink,
          email,
          zip,
          city,
          country: countryOptions.find(({ name }) => name === country),
          companySupportDescription,
          companyStrenghts,
          categories: companyCategories?.map((item) => {
            let translation;
            try {
              translation = ct(item);
            } catch (err) {
              translation = item;
            }
            return {
              name: translation,
              label: translation,
              value: item,
            };
          }) || [],
        });
      }
    }, [details]);
    return (
      <form onSubmit={handleSubmit} className="form">
        <Container>
          <Row style={{ alignItems: 'center' }}>
            <Col lg={3} md={12}>
              <div className="form__form-group">
                <p className="form-header">{s('name')}</p>
                <Field
                  component={renderField}
                  disabled
                  placeholder={details?.name}
                />
              </div>
            </Col>
            <Col lg={2} md={12}>
              <div className="form__form-group">
                <p className="form-header">{s('organizationNumber')}</p>
                <Field
                  component={renderField}
                  disabled
                  placeholder={details?.organization_number}
                />
              </div>
            </Col>
            <Col lg={5} md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('companyCategory')}</p>
                <Field
                  disabled
                  name="categories"
                  component={renderMultiSelectField}
                  placeholder={p('companyCategory')}
                />
              </div>
            </Col>
            <Col lg={2} md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('companyLevel')}</p>
                <Field
                  placeholder={details?.companyLevel}
                  component={renderField}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row style={{ alignItems: 'center' }}>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <p className="form-header">{s('countryCode')}</p>
                <Field
                  name="countryCode"
                  component={renderField}
                  type="number"
                  placeholder={s('countryCodePlaceHolder')}
                />
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('phoneNumber')}</p>
                <Field name="phone" component={renderField} type="text" placeholder="12345678" />
              </div>
            </Col>
            <Col lg={2} md={12}>
              <Field
                name="isSponsor"
                component={renderCheckBoxField}
                label={p('isSponsor')}
                className="colored-click marginTop-15"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <p className="form-header">{s('address')}</p>
                <Field
                  name="address"
                  component={renderField}
                  placeholder={s('address')}
                />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <p className="form-header">{t('EventsForm.googleMapsLink')}</p>
                <Field
                  name="addressLink"
                  component={renderField}
                  placeholder={t('EventsForm.googleMapsLink')}
                />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('companyEmail')}</p>
                <Field
                  name="email"
                  component={renderField}
                  placeholder={p('companyEmail')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('zip')}</p>
                <Field name="zip" component={renderField} placeholder="1234" />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('city')}</p>
                <Field name="city" component={renderField} placeholder="City" />
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('country')}</p>
                <Field
                  name="country"
                  component={renderSelectField}
                  options={countryOptions}
                  placeholder={s('chooseCountry')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('companyDescription')}</p>
                <Field
                  name="companyStrenghts"
                  component="textarea"
                  type="textarea"
                  placeholder={p('companyDescription')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <p className="form-header">{p('descriptionSupportingClub')}</p>
                <Field
                  name="companySupportDescription"
                  component="textarea"
                  type="textarea"
                  placeholder={p('descriptionSupportingClub')}
                />
              </div>
            </Col>
          </Row>
          <Col>
            <Button type="submit" color="primary">{s('save')}</Button>
            <Button color="secondary" onClick={goBack}>{s('cancel')}</Button>
          </Col>
        </Container>
      </form>
    );
}

export default reduxForm({
  form: 'offers_form',
  validate: validateDetails,
})(CompanyClubDetailsForm);
