import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';
import AuthService from '../../_app/AuthService';
import { partial } from '../../util/translation/translation';
// import storage from '../../util/storage';

const s = partial('shared');
class SidebarContent extends Component {
  hideSidebar = () => {
    this.props.onClick();
  };

  logOut = () => {
    AuthService.signout(this.props.dispatch);
  };

  render() {
    return (
      <div className="sidebar__content">
        {/* <ul className="sidebar__block">
          <SidebarLink title={s('dashboard')} icon="home" route="/" onClick={this.hideSidebar} />
        </ul> */}
        {/* <ul className="sidebar__block">
          <SidebarLink title={s('profile')} icon="profile" route="/profile" onClick={this.hideSidebar} />
          <SidebarLink title={s('sponsorMaterial')} icon="picture" route={`/sponsorMaterial/${storage.getCurrentCompany().id}`} onClick={this.hideSidebar} />
        </ul> */}
        {/* <ul className="sidebar__block">
          <SidebarCategory title={s('offers')} icon="bullhorn">
            <SidebarLink title={s('active')} route="/ui/alerts" onClick={this.hideSidebar} />
            <SidebarLink title={s('history')} route="/ui/buttons" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title={s('news')} icon="news">
            <SidebarLink title={s('latest')} route="/ui/alerts" onClick={this.hideSidebar} />
            <SidebarLink title={s('history')} route="/mail" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title={s('events')} icon="calendar-full">
            <SidebarLink title={s('upcoming')} route="/chat" onClick={this.hideSidebar} />
            <SidebarLink title={s('ongoing')} route="/mail" onClick={this.hideSidebar} />
            <SidebarLink title={s('history')} route="/mail" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul> */}
        <ul className="sidebar__block">
          <SidebarLink title={s('myClub')} icon="pushpin" route="/clubs" onClick={this.hideSidebar} />
          <SidebarLink title={s('contactPersons')} icon="users" route="/employees" onClick={this.hideSidebar} />
          <SidebarLink title={s('offers')} icon="bullhorn" route="/offers" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title={s('logOut')} icon="exit" route="/log_in" onClick={this.logOut} />
        </ul>
      </div>
    );
  }
}
SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default connect(() => ({}))(SidebarContent);
