import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Col, Card, CardBody, Button } from 'reactstrap';
import PhoneIcon from 'mdi-react/PhoneIcon';
import MailIcon from 'mdi-react/EmailIcon';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { partial } from '../util/translation/translation';

const s = partial('shared');
const r = partial('Reports');

const BusinessCard = (props) => {
  const history = useHistory();
  const { id, firstName, lastName, email, phone, positionTitle, profileImage = undefined, visibleCompany, userId, visiblePrivate, affiliations } = props.contactDetail;
  const hasUserConnected = !!userId;
  const userTeams = affiliations.join(', ');
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            {profileImage ? (
              <div className="profile__avatar">
                <img src={profileImage} alt="avatar" />
              </div>
            ) : (
              <div className="profile__avatar_placeholder">{firstName[0]?.toUpperCase()}</div>
            )}
            <div className="profile__data">
              <p className="profile__name">{`${firstName} ${lastName}`}</p>
              {positionTitle && <p className="profile__work">{positionTitle}</p>}
              {email && (
                <p className="profile__contact">
                  <MailIcon size={14} /> {email}
                </p>
              )}
              {phone && (
                <p className="profile__contact">
                  <PhoneIcon size={14} /> {phone}
                </p>
              )}
              <p className={`profile__contact ${visibleCompany ? 'success' : 'danger'}`}>{visibleCompany ? r('visibleForPartners') : r('notVisibleForPartners')}</p>
              <p className={`profile__contact ${visiblePrivate ? 'success' : 'danger'}`}>{visiblePrivate ? r('visibleForPublic') : r('notVisibleForPublic')}</p>
              <p className={`profile__contact ${hasUserConnected ? 'success' : 'danger'}`}>{hasUserConnected ? s('userConnected') : s('userDisconnected')}</p>
              <p className="profile__contact">{s('affiliatedWith')}: {userTeams}</p>
            </div>
            <div className="profile_button_container">
              <Button className="icon" color="secondary" onClick={() => history.replace(`/employee/${id}`)}>
                <p>
                  <EditIcon /> {s('edit')}
                </p>
              </Button>
              <Button className="icon" color="danger" outline onClick={() => props.handleDeleteEmployee(id)}>
                <p>
                  <DeleteIcon /> {s('delete')}
                </p>
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

BusinessCard.propTypes = {
  contactDetail: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    positionTitle: PropTypes.string,
    profileImage: PropTypes.string,
  }).isRequired,
};
export default BusinessCard;
