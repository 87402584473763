import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class SelectField extends PureComponent {
  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  render() {
    const { value, name, placeholder, options, disabled, multiple, className } = this.props;
    return (
      <Select
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className={`form__form-group-select ${className}`}
        placeholder={placeholder}
        disabled={disabled}
        multi={multiple}
      />
    );
  }
}

SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};
SelectField.defaultProps = {
  placeholder: '',
  options: [],
  disabled: false,
};

const renderSelectField = (props) => {
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...props.input}
        options={props.options}
        placeholder={props.placeholder}
        disabled={props.disabled}
        multiple={props.multiple}
        className={props.className}
      />
      {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string,
        }),
      ]).isRequired,
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  className: PropTypes.string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  disabled: false,
  multiple: false,
  className: '',
};

export default renderSelectField;
