import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import burgerIcon from '../../shared/img/icons/burger.svg';

class TopbarSidebarButton extends PureComponent {
  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } =
      this.props;

    return (
      <div>
        <button
          className="topbar__button topbar__button--desktop"
          onClick={changeSidebarVisibility}
        >
          <img src={burgerIcon} alt="" className="topbar__button-icon" />
        </button>
        <button
          className="topbar__button topbar__button--mobile"
          onClick={changeMobileSidebarVisibility}
        >
          <img src={burgerIcon} alt="" className="topbar__button-icon" />
        </button>
      </div>
    );
  }
}
TopbarSidebarButton.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default TopbarSidebarButton;
