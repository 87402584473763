import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import storage from '../../util/storage';

const TopbarCompany = ({ companies, company }) => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  const handleCompanyChange = (companyItem) => {
    storage.setCurrentCompany(companyItem);
    window.location.reload();
  };
  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" onClick={toggle}>
        <div className="topbar__avatar-icon">
          {company?.generalName?.[0]?.toUpperCase()}
        </div>
        <p className="topbar__avatar-name" style={{ color: '#20c997' }}>
          {company?.generalName || ''}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      <Collapse className="companyBar" isOpen={collapse}>
        <div className="topbar__menu">
          {companies?.map((companyItem) => {
            return (
              companyItem && (
                <button
                  key={`topBarCompany-${companyItem.id}`}
                  className="topbar__link btn-noStyle"
                  onClick={() => handleCompanyChange(companyItem)}
                >
                  {companyItem.generalName}
                </button>
              )
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

TopbarCompany.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  company: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

TopbarCompany.defaultProps = {
  companies: null,
  company: null,
};

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
    companies: state.auth.companies,
  }))(TopbarCompany),
);
