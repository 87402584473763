import React, { useEffect, useState, useMemo } from 'react';
import { Container, Button, Table, Card, CardBody, Collapse, Row, Col, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { connect } from 'react-redux';
import Loading from '../shared/components/custom/Loading';
import EmployeeDeleteModal from '../shared/components/custom/EmployeeDeleteModal';
import Api from '../util/api';
import { partial } from '../util/translation/translation';
import storage from '../util/storage';
import { capitalizeFirstLetter } from '../util/functions';
import InitialsImage from '../shared/components/custom/InitialsImage';
import ClubInfo from '../offers/components/ClubInfo';
import TeamRow from './TeamRow';

const p = partial('Employee');
const s = partial('shared');

const EmployeeListPage = ({ clubsData }) => {
  const [employees, setEmployees] = useState([]);
  const [visibleClubs, setVisibleClubs] = useState({});
  const clubMap = useMemo(() => clubsData.clubs.reduce((tot, curr) => ({ ...tot, [curr.clubId]: curr }), {}), [clubsData]);

  const [loading, setLoading] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const employeeGroups = useMemo(() => {
    return employees.filter((emp) => emp.combinedName.includes(searchQuery)).reduce((tot, curr) => {
      if (!tot[curr.clubId]) {
        return { ...tot, [curr.clubId]: { employees: [curr] } };
      }
      return { ...tot, [curr.clubId]: { employees: [...tot[curr.clubId].employees, curr] } };
    }, {});
  }, [employees, searchQuery, clubMap]);
  // Object.keys(clubMap).reduce((tot, curr) => ({ ...tot, [curr]: { employees: [] } }), {})
  const companyID = storage.getCurrentCompany().id;
  const handleModal = (id) => {
    setEmployeeToDelete(id);
    setShowModal(true);
  };
  const getAllEmployees = async () => {
    setLoading(true);
    try {
      const resEmployees = await Api.employees.getAllEmployees(companyID);
      const mappedEmployees = resEmployees.map((item) => ({ ...item, combinedName: `${item.firstName} ${item.lastName}` }));
      setEmployees(mappedEmployees);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(p('employeeFetchError'));
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);
  useEffect(() => {
    setVisibleClubs(clubsData?.clubs.reduce((tot, curr) => ({ ...tot, [curr.clubId]: true }), {}));
  }, [clubsData]);
  const getEmployeeVisibility = ({ visibleCompany, visiblePrivate }) => {
    if (visibleCompany && visiblePrivate) {
      return (<Badge color="success"> {s('all')}</Badge>);
    } else if (visibleCompany) {
      return (<Badge color="info"> {p('partners')}</Badge>);
    } else if (visiblePrivate) {
      return (<Badge color="info"> {p('public')}</Badge>);
    } else {
      return (<Badge> {p('none')}</Badge>);
    }
  };

  const handleEmployeeDelete = async (text) => {
    if (employeeToDelete) {
      try {
        setLoading(true);
        const payload = {
          deleteComment: text,
        };
        await Api.employees.removeEmployee(employeeToDelete, payload);
        setShowModal(false);
        toast.success(p('employeeDeleteSuccess'));
        getAllEmployees();
      } catch (err) {
        toast.error(p('employeeDeleteError'));
        setLoading(false);
      }
    }
  };
  const renderEmployee = (employee) => {
    const name = `${employee.firstName} ${employee.lastName}`;
    const initials = `${employee.firstName.slice(0, 1).toUpperCase()}${employee.lastName.slice(0, 1).toUpperCase()}`;
    return (
      <tr key={employee.id}>
        <td>
          <div className="alignItems-center flex-row marginVertical-10">
            <InitialsImage
              name={initials}
              imageUrl={employee?.profileImage}
              wrapperClass="employee-profile-image"
            />
            <div className="paddingHorizontal-10 flex-Hcenter flex-column">
              <span style={{ fontSize: 15 }}>
                <div style={{ width: 5, height: 5, borderRadius: 5, backgroundColor: employee.userId ? '#42e2b5' : '#aa2626', marginRight: 5, display: 'inline-block', verticalAlign: 'middle' }}/>
                {name}
              </span>
              <strong style={{ marginLeft: 10, fontSize: 12 }}>
                {employee.positionTitle}
              </strong>
              <span style={{ marginLeft: 10, fontSize: 12 }}>
                {employee.email}
              </span>
              <span style={{ marginLeft: 10, fontSize: 12 }}>
                {employee.phone}
              </span>
            </div>
          </div>
        </td>
        <td>
          <Badge color={employee.userId ? 'success' : 'danger'}>{employee.userId ? s('connected') : s('disconnected')}</Badge>
        </td>
        <td><ClubInfo clubIds={[employee.clubId]} /></td>
        <td>{getEmployeeVisibility(employee)}</td>
        <td>
          <div className="flex">
            <Link className="ml-3 action-btn" to={`/employee/${employee.id}`}>
              <EditIcon size={14} />
              <div style={{ paddingLeft: 7 }}>{s('edit')}</div>
            </Link>
            <button className="ml-3 action-btn" style={{ backgroundColor: 'white' }} onClick={() => handleModal(employee.id)}>
              <DeleteIcon size={14} color="red" />
              <div className="action-btn__danger" style={{ paddingLeft: 7 }}>{s('delete')}</div>
            </button>
          </div>
        </td>
      </tr>
    );
  };
  const handleExpandPress = (teamId) => {
    setVisibleClubs(prev => ({ ...prev, [teamId]: !prev[teamId] }));
  };
  const renderEmployeeGroup = (teamId, group) => {
    const isVisible = visibleClubs[teamId];
    return (
      <div key={teamId} style={{ marginBottom: 20 }}>
        <TeamRow team={clubMap[teamId]} visible={isVisible} onPress={handleExpandPress}/>
        <Collapse className="companyBar" isOpen={isVisible}>
          <Table responsive bordered className="mt-3">
            <thead>
              <tr>
                <th>{capitalizeFirstLetter(s('employee'))}</th>
                <th>{s('status')}</th>
                <th>{s('club')}</th>
                <th>{p('visibleFor')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {group.employees.map(employee => renderEmployee(employee))}
            </tbody>
          </Table>
        </Collapse>
      </div>
    );
  };

  return (
    <>
      <Container>
        <Loading loading={loading} />
        <Row>
          <Col>
            <p className="page-title">{s('contactPersons')}</p>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <div className="flex justify-content-end">
              <div className="form noWrap mr-3">
                <div className="form__form-group products-list__search flex">
                  <input
                    value={searchQuery}
                    placeholder={s('search')}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <Link to="/addEmployee">
                <Button size="sm" color="primary">{p('addEmployee')}</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            {Object.entries(employeeGroups).map(([teamId, group]) => renderEmployeeGroup(teamId, group))}
          </CardBody>
        </Card>
      </Container>
      <EmployeeDeleteModal
        showModal={employeeToDelete && showModal}
        handleDelete={handleEmployeeDelete}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default connect((state) => ({
  clubsData: state.clubs,
}))(withRouter(EmployeeListPage));

