import React, { useState } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useRecoilState } from 'recoil';
import { partial } from '../../util/translation/translation';
import userLanguageState from '../../../recoil/selector/selector';

const getDefaultCountry = () => {
  const [lang] = useRecoilState(userLanguageState);
  switch (lang) {
     case 'no':
       return 'NO';
     case 'sv':
       return 'SE';
     case 'en':
       return 'GB';
     default:
       return 'NO';
  }
};
const RenderCountryPhone = ({ input }) => {
  const defaultCountry = getDefaultCountry();
  const [country, setCountry] = useState(defaultCountry);
  const s = partial('shared');
  return (
    <PhoneInput
      style={{ width: '100%' }}
      defaultCountry={defaultCountry}
      placeholder={s('phonenumber')}
      onCountryChange={setCountry}
      onChange={(value) => input.onChange({ phone: value, code: getCountryCallingCode(country) })}
    />
  );
};
export default RenderCountryPhone;
