import React, { useEffect } from 'react';
import { Col, Button } from 'reactstrap';
import {
  reduxForm,
  Field,
  propTypes as reduxFormProps,
  getFormValues,
} from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderDropZoneCropField from '../shared/components/form/DropZoneMS';
import { partial } from '../util/translation/translation';
import coverToolTipImg from '../../assets/header-img.png';
import partnerToolTipImg from '../../assets/partner-img.png';
import coverToolTipFullImg from '../../assets/full-header-img.png';
import partnerToolTipFullImg from '../../assets/full-partner-img.png';
import TooltipWithIcon from '../shared/components/custom/TooltipWithIcon';

const s = partial('shared');
const p = partial('SponsorForm');
const SponsorForm = ({
  handleSubmit,
  company,
  initialize,
  formValues,
}) => {
  /* eslint-disable indent */
  useEffect(() => {
    if (company && company.id) {
      initialize({
        sponsor: !!company.sponsor,
        show: !!company.show,
        sponsorUrl: company.sponsor_url,
        companyLogo: company.imagesUrls?.sqNormal
          ? {
            name: 'company-logo',
            preview: company.imagesUrls?.sqNormal,
          }
          : null,
        coverImage: company.imagesUrls?.rectNormal
          ? {
            name: 'cover-image',
            preview: company.imagesUrls?.rectNormal,
          }
          : null,
        appTicket: company.appTicketImage
          ? {
            name: 'app-ticket-image',
            preview: company.appTicketImage,
          }
          : null,
        print: company.printImage
          ? {
            name: 'app-print-image',
            preview: company.printImage,
          }
          : null,
        player: company.playerImage
          ? {
            name: 'app-player-image',
            preview: company.playerImage,
          }
          : null,
        appSponsorLogo: company.imagesUrls?.appSponsorLogo
          ? {
              name: 'app-sponsor-logo',
              preview: company.imagesUrls?.appSponsorLogo,
            }
          : null,
      });
    }
  }, [company]);
  return (
    <>
      <Col className="mb-30">
        <h4 className="bold text-dark">{p('uploadImages')}</h4>
        <p>{p('uploadImagesInfo')}<br />{p('uploadImagesInfo2')}<br />{p('uploadImagesInfo3')}</p>
      </Col>

      <form onSubmit={handleSubmit} className="form">
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('companyImageInApp')}
              <TooltipWithIcon text={partnerToolTipImg} id="profileImage" fullImageUrl={partnerToolTipFullImg} isImageUrl />
              <p>{p('companyImageInAppInfo')}<br />{p('recommendedImgSizeCompanyLogo')}</p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="companyLogo" component={renderDropZoneCropField} crop="PROFILE_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="w-100">
            {formValues.companyLogo && <img className="company-logo" src={formValues.companyLogo.croppedImage || formValues.companyLogo?.preview} alt="" />}
          </div>
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('companyCoverImageInApp')}
              <TooltipWithIcon text={coverToolTipImg} id="coverImage" fullImageUrl={coverToolTipFullImg} isImageUrl />
              <p>
                {p('companyCoverImageInAppInfo')}<br />{p('recommendedImgSizeCompanyCover')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="coverImage" component={renderDropZoneCropField} crop="BANNER_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.coverImage && <img className="company-cover" src={formValues.coverImage.croppedImage || formValues.coverImage?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('matchSponsor')}
              <p>
                {p('imageOverTicketValidation')}
                <br />
                {p('recommendedImgSizeMatchSponsor')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="appTicket" component={renderDropZoneCropField} crop="MATCHSPONSOR_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.appTicket && <img className="sponsor-app sponsor-app-ticket" src={formValues.appTicket.croppedImage || formValues.appTicket?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('playerCard')}
              <p>
                {p('playerImage')}
                <br />
                {p('recommendedImgSizePlayerImage')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field
              name="player"
              component={renderDropZoneCropField}
              style={{ width: 'auto', height: 'auto' }}
              crop="PLAYERCARD_CROP"
            />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.player && <img className="sponsor-player bg-dark" src={formValues.player.croppedImage || formValues.player?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('printedPaperTicket')}
              <p>
                {p('boxOffice&WebSalesImage')}
                <br />
                {p('recommendedImgSizePrintedPaperTicket')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field
              name="print"
              component={renderDropZoneCropField}
              style={{ width: 'auto', height: 'auto' }}
              crop="PRINTEDPAPERTICKET_CROP"
            />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.print && <img className="sponsor-print" src={formValues.print.croppedImage || formValues.print?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('appSponsorInApp')}
              <p>
                {p('appSponsorLogoInAppInfo')}
                <br />
                {p('recommendedImgSizeAppSponsorLogo')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field
              name="appSponsorLogo"
              component={renderDropZoneCropField}
              style={{ width: 'auto', height: 'auto' }}
              crop="APP_SPONSOR_CROP"
            />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.appSponsorLogo && <img className="app-sponsor-logo" src={formValues.appSponsorLogo.croppedImage || formValues.appSponsorLogo?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col>
          <Button color="primary">{p('saveSponsor')}</Button>
          <Link to="/companies"><Button color="secondary">{s('cancel')}</Button></Link>
        </Col>
      </form>
    </>
  );
};

SponsorForm.propTypes = {
  ...reduxFormProps,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    app: PropTypes.arrayOf(
      PropTypes.shape({
        preview: PropTypes.string,
      }),
    ),
    print: PropTypes.arrayOf(
      PropTypes.shape({
        preview: PropTypes.string,
      }),
    ),
  }),
};

SponsorForm.defaultProps = {
  company: null,
  formValues: {},
};

const reduxF = reduxForm({
  form: 'company_sponsor_form',
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('company_sponsor_form')(state),
});

export default connect(mapStateToProps)(reduxF(SponsorForm));
