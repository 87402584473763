import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import validateLogin from './validateLogin';
// import renderCheckBoxField from "../../shared/components/form/CheckBox";
import t, { partial } from '../../util/translation/translation';
import RenderCountryPhone from './CountryPhone';

const p = partial('LogInForm');
const LogInForm = ({ handleSubmit, loading, goToEmail }) => {
  const formName = p('smsLogin');

  // const RememberButton = () => {
  //   return (
  //     <div className="form__form-group">
  //       <div className="form__form-group-field">
  //         <Field name="rememberMe" component={renderCheckBoxField} label={p("rememberMe")} />
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group my-30">
        <span className="form__form-group-label loginpages-title">{formName}</span>
        <div className="form__form-group-field">
          <Field
            name="username"
            component={RenderCountryPhone}
            placeholder={t('shared.username')}
            autoComplete="username"
          />
        </div>
      </div>
      <Button color="primary" className="width-100p margin-0 bold-text" disabled={loading}>
        {loading ? <Spinner size="sm" /> : p('logIn')}
      </Button>
      <p className="account__or">
        <span>{p('or')}</span>
      </p>
      <Button color="secondary" className="width-100p bold-text" outline disabled={loading} onClick={goToEmail}>
        {p('goToEmail')}
      </Button>
    </form>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'log_in_form',
  validateLogin,
});

export default reduxF(LogInForm);
