const OfferTypes = {
    TargetAudience: {
        partner: 'partner',
        private: 'private',
        both: 'both',
    },
    Tiers: {
        allTiers: 'all',
    },
};

export default OfferTypes;
