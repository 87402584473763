import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarCompany from './TopbarCompany';
import TopbarLanguage from './TopbarLangauge';
import storage from '../../util/storage';
import { partial } from '../../util/translation/translation';
import Api from '../../util/api';
import { setCompanyClubs } from '../../redux/actions/clubActions';

const p = partial('ClubListPage');
const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility, companies, dispatch }) => {
  const [currentCompany, setCurrentCompany] = useState();
  useEffect(() => {
    if (companies?.length) {
      const company = storage.getCurrentCompany();
      if (company) {
        const filteredCompany = companies.find((item) => item.id === company.id);
        setCurrentCompany(filteredCompany);
      } else {
        setCurrentCompany(companies[0]);
      }
    }
  }, [companies]);
  const getAllCompanyClubinfo = async (clubIds) => {
    try {
      if (clubIds?.length) {
        const queries = clubIds.map(({ clubId }) => Api.club.getClubById(clubId));
        const result = await Promise.all(queries);
        dispatch(setCompanyClubs(result));
      }
    } catch (err) {
      toast.error(p('clubInfoFetchError'));
    }
  };

  const getClubs = async () => {
    try {
      const clubIds = await Api.company.getCompanyClubs(currentCompany.id);
      await getAllCompanyClubinfo(clubIds);
    } catch (err) {
      toast.error(p('clubFetchError'));
    }
  };

  useEffect(() => {
    if (currentCompany) {
      getClubs();
    }
  }, [currentCompany]);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
        </div>
        <div className="topbar__right">
          <TopbarLanguage />
          <TopbarCompany company={currentCompany} />
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.shape(),
    }),
  ),
};

Topbar.defaultProps = {
  companies: [],
};
export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
    companies: state.auth.companies,
    clubsData: state.clubs,
  }))(Topbar),
);
