import { useState, useMemo } from 'react';

const useConfig = () => {
    const [fepContext, setFepContext] = useState('');
    const [companyId, setCompanyId] = useState({});
    useMemo(() => {
        const company = JSON.parse(localStorage.getItem('currentCompany'));
        if (company.clubRelations && company.clubRelations.length > 0) {
            setFepContext(company.clubRelations.map(club => club.clubId));
        }
        setCompanyId(JSON.parse(localStorage.getItem('currentCompany')).id);
    }, []);
    return [companyId, fepContext];
};

export default useConfig;
