import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import Api from '../util/api';
import Loading from '../shared/components/custom/Loading';
import { partial } from '../util/translation/translation';
import storage from '../util/storage';
import SponsorForm from './SponsorForm';

const s = partial('shared');
const p = partial('CompanyFormPage');
const SponsorMaterialPage = () => {
    const { companyID } = useParams();

    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({});

    const history = useHistory();

    const getCompanyInformation = async () => {
        setLoading(true);
        try {
            if (companyID) {
                const currentCompanyId = storage.getCurrentCompany().id;
                if (companyID !== currentCompanyId) {
                    history.replace(`/profile/${currentCompanyId}`);
                }
                const resCompany = await Api.company.getCompany(companyID);
                setLoading(false);
                setCompany(resCompany);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err || `${p('companyInfoFetchingERROR')}!`);
        }
    };

    useEffect(() => {
        getCompanyInformation();
    }, [companyID]);

    const handleSponsorSubmit = async (values) => {
        setLoading(true);
        try {
            const payload = {
                imagesUrls: {
                    ...company.imagesUrls,
                    sqNormal: (await values?.companyLogo?.promise?.('company', 'sqNormal', companyID)) || values?.companyLogo?.preview.includes('blob')
                        ? undefined
                        : values?.companyLogo?.preview || null,
                    rectNormal: (await values?.coverImage?.promise?.('company', 'rectNormal', companyID)) || values?.coverImage?.preview.includes('blob')
                        ? undefined
                        : values?.coverImage?.preview || null,
                    appSponsorLogo: (await values?.appSponsorLogo?.promise?.('company', 'appSponsorLogo', companyID)) || values?.appSponsorLogo?.preview.includes('blob')
                        ? undefined
                        : values?.appSponsorLogo?.preview || null,
                },
                appTicketImage: (await values?.appTicket?.promise?.('appTicket', 'sqNormal', companyID)) || values?.appTicket?.preview.includes('blob')
                    ? undefined
                    : values?.appTicket?.preview || null,
                printImage: (await values?.print?.promise?.('print', 'sqNormal', companyID)) || values?.print?.preview.includes('blob')
                    ? undefined
                    : values?.print?.preview || null,
                playerImage: (await values?.player?.promise?.('player', 'sqNormal', companyID)) || values?.player?.preview.includes('blob')
                    ? undefined
                    : values?.player?.preview || null,
            };
            await Api.company.editCompany(payload, companyID);
            toast.success(`${p('sponsorUpdated')}`);
            setLoading(false);
        } catch (err) {
            toast.error(p('sponsorUpdatingERROR'));
            setLoading(false);
        }
    };

    return (
      <Container>
        <Loading loading={loading} />
        <div>
          <h3 className="page-title">
            {s('edit')} {s('sponsorMaterial')}
          </h3>
        </div>
        <Card>
          <CardBody>
            <div className="tabs tabs--justify tabs--bordered-top overflowY-hidden">
              <SponsorForm
                company={company}
                onSubmit={handleSponsorSubmit}
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
};

export default SponsorMaterialPage;
