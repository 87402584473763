import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelectField } from './MultiSelect';

const renderMultiSelectField = props => (
  <div className="form__form-group-input-wrap">
    <MultiSelectField
      {...props.input}
      options={props.options}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
  );
  renderMultiSelectField.propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    placeholder: PropTypes.string,
  };

  renderMultiSelectField.defaultProps = {
    meta: null,
    options: [],
    placeholder: '',
  };

  export default renderMultiSelectField;


