import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Button, Row } from 'reactstrap';
import countries from 'i18n-iso-countries';
import { parsePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import ContactValidate from './contactInfoValidate';
import renderField from '../shared/components/custom/Field';
import TooltipWithIcon from '../shared/components/custom/TooltipWithIcon';
import renderDropZoneCropField from '../shared/components/form/DropZoneWithCrop';
import renderCheckBoxField from '../shared/components/form/CheckBox';
import t, { partial } from '../util/translation/translation';
import renderSelectField from '../shared/components/form/Select';

const s = partial('shared');
const p = partial('Employee');
countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const AddEmployeeForm = ({ handleSubmit, initialize, user, type, clubsData, formValues }) => {
  const clubsToDisplay = useMemo(() => clubsData.clubs.map((item) => ({ label: item.name, value: item })), [clubsData]);
  const history = useHistory();
  const hasUserConnected = !!user?.userCloudId;
  let countryCode;
  let phoneNumber;
  try {
    const parsedPhone = parsePhoneNumber(user.phoneNumber);
    countryCode = parsedPhone.countryCallingCode;
    phoneNumber = parsedPhone.nationalNumber;
  } catch (err) {
    countryCode = undefined;
    phoneNumber = undefined;
  }
  useEffect(() => {
    if (user && user.userCloudId) {
      initialize({
        firstName: user.firstname,
        lastName: user.lastname,
        phoneNumber,
        countryCode,
        email: user.email,
        userId: user.userCloudId,
        image: user.profileImage ? [{ preview: user.profileImage, name: `${user.firstName}-logo` }] : undefined,
        visibleCompany: false,
        visiblePrivate: false,
        club: null,
      });
    } else {
      initialize({
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        countryCode: undefined,
        email: undefined,
        userId: undefined,
        positionTitle: undefined,
        visibleCompany: false,
        visiblePrivate: false,
        club: null,
      });
    }
  }, [user]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <Row>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('firstName')}</span>
              <Field name="firstName" component={renderField} placeholder={p('firstName')} />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('lastName')}</span>
              <Field name="lastName" component={renderField} placeholder={p('lastName')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{t('OfferForm.selectClub')}*</span>
              <Field
                name="club"
                placeholder={t('OfferForm.selectClub')}
                options={clubsToDisplay}
                component={renderSelectField}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('companyPosition')}</span>
              <Field name="positionTitle" component={renderField} placeholder={p('companyPosition')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('email')}*</span>
              <Field name="email" component={renderField} placeholder={p('email')} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <p className="form-header">{s('countryCode')}</p>
              <Field
                name="countryCode"
                component={renderField}
                type="number"
                placeholder={s('countryCodePlaceHolder')}
              />
            </div>
          </Col>
          <Col lg={8} md={12}>
            <div className="form__form-group">
              <p className="form-header">{p('phoneNumber')}</p>
              <Field name="phoneNumber" component={renderField} type="text" placeholder="12345678" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <p className="form-header">{t('Reports.visibilityInApp')}</p>
              <Field
                name="visibleCompany"
                component={renderCheckBoxField}
                label={t('Reports.visibleForPartners')}
                className="colored-click"
              />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <Field
                name="visiblePrivate"
                component={renderCheckBoxField}
                label={t('Reports.visibleForPublic')}
                className="colored-click marginTop-20"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <p className={`profile__contact ${hasUserConnected ? 'success' : 'danger'}`}>{hasUserConnected ? s('userConnected') : s('userDisconnected')}</p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg={6} md={12}>
        <Row>
          <Col lg={12}>
            <div className="form__form-group">
              <span className="form-header">
                {p('profilePicture')} (750x750px)
                <TooltipWithIcon text="This image will appear in profile" id="profileImage" />
              </span>
              <Field name="image" component={renderDropZoneCropField} crop="PROFILE_CROP" />
            </div>
          </Col>
        </Row>
      </Col>
      <Col>
        <Button type="submit" color="primary" disabled={!formValues?.club}>
          {s(type === 'create' ? 'create' : 'edit')}
        </Button>
        <Button className="color-unset" onClick={() => history.replace('/employees')}>
          {s('close')}
        </Button>
      </Col>
    </form>
  );
};

AddEmployeeForm.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
    countryCode: PropTypes.string,
    email: PropTypes.string,
  }),
  type: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

AddEmployeeForm.defaultProps = {
  user: undefined,
  type: 'edit',
};

const reduxF = reduxForm({
  form: 'addEmployeeForm',
  validate: ContactValidate,
});

const mapStateToProps = (state) => {
  return ({
    clubsData: state.clubs,
    formValues: getFormValues('addEmployeeForm')(state),
  });
};

export default connect(mapStateToProps)(reduxF(AddEmployeeForm));
