import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Table, Col, ButtonToolbar, ButtonGroup } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Loading from '../shared/components/custom/Loading';
import Api from '../util/api';
import t, { partial } from '../util/translation/translation';
import storage from '../util/storage';
import IconButton from '../shared/components/custom/IconButton';
import CompanyProfileModal from './CompanyProfileModal';

const s = partial('shared');
const p = partial('ClubListPage');
function ClubListPage({ history, clubsData }) {
  const [loading, setLoading] = useState(false);
  const [companyClubList, setCompanyClubList] = useState([]);
  const [visibleProfile, setVisibleProfile] = useState(null);
  const companyID = storage.getCurrentCompany().id;
  const getAllCompanyClubs = async () => {
    try {
      const foundLevels = await Api.company.getCompanyAllCompanyLevels(companyID);
      const levelsMap = foundLevels.reduce((tot, cur) => ({ ...tot, [cur.clubId]: cur.fieldValue }), {});
      const clubDetails = clubsData.clubs.map((data) => ({ ...data, companyLevel: levelsMap[data.clubId] }));
      setCompanyClubList(clubDetails);
    } catch (err) {
      toast.error(p('clubFetchError'));
    }
  };
  useEffect(() => {
    if (clubsData.clubs.length) {
      getAllCompanyClubs();
    }
  }, [companyID, clubsData]);
  const getCompanyProfile = async ({ shortName: clubShortName, clubId }) => {
    setLoading(true);
    try {
      const [companyClubDetails, employeeList] = await Promise.all([
        Api.company.getCompany(companyID, clubId),
        Api.employees.getAllEmployees(companyID),
      ]);
      const filteredEmployees = employeeList.filter((item) => item.clubId === clubId);
      setLoading(false);
      setVisibleProfile({ ...companyClubDetails, clubShortName, employees: filteredEmployees });
    } catch (err) {
      setLoading(false);
      toast.error(p('clubFetchError'));
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <CompanyProfileModal details={visibleProfile} handleModal={() => setVisibleProfile(null)}/>
      <Col>
        <p className="page-title">{s('myClub')}</p>
      </Col>
      <Card>
        <CardBody>
          <Table responsive bordered size="sm">
            <thead>
              <tr>
                <th />
                <th>{s('name')}</th>
                <th>{s('email')}</th>
                <th>{s('phone')}</th>
                <th>{s('website')}</th>
                <th>{t('CompanyForm.companyLevel')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {companyClubList.map((item) => (
                <tr key={`company-club-list-item-${item.id}`}>
                  <td>
                    <Link to="/clubs" style={{ marginRight: '10px' }}>
                      <img src={item?.imageUrl} height={30} width={30} alt="logo" />
                    </Link>
                  </td>
                  <td>{item?.name.toUpperCase()}</td>
                  <td>{item?.contactMail}</td>
                  <td>{item?.contactPhone}</td>
                  <td>{item?.homepage}</td>
                  <td>{item?.companyLevel}</td>
                  <td>
                    <ButtonToolbar className="float-right">
                      <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                        <IconButton onClick={() => history.push(`/clubs/edit/${item.clubId}`)} type={IconButton.types.EDIT} id={`edit-${item.clubId}`}/>
                        <IconButton onClick={() => getCompanyProfile(item)} type={IconButton.types.VIEW} id={`add-${item.clubId}`}/>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
}

export default withRouter(
  connect((state) => ({
    clubsData: state.clubs,
  }))(ClubListPage),
);


