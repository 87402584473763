import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import Api from '../util/api';
import Loading from '../shared/components/custom/Loading';
import { partial } from '../util/translation/translation';
import CompanyProfileForm from './CompanyProfileForm';
import storage from '../util/storage';

const s = partial('shared');
const p = partial('CompanyFormPage');
const CompanyProfileEitPage = () => {
  const { companyID } = useParams();

  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});

  const history = useHistory();

  const getCompanyInformation = async () => {
    setLoading(true);
    try {
      if (companyID) {
        const currentCompanyId = storage.getCurrentCompany().id;
        if (companyID !== currentCompanyId) {
          history.replace(`/profile/${currentCompanyId}`);
        }
        const resCompany = await Api.company.getCompany(companyID);
        setLoading(false);
        setCompany(resCompany);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('companyInfoFetchingERROR')}!`);
    }
  };

  useEffect(() => {
    getCompanyInformation();
  }, [companyID]);

  const handleBusinessInfoSubmit = async (values) => {
    const phone = `+${values.countryCode}${values.phoneNumber}`;
    const payload = {
      name: values.orgName,
      organization_number: values.orgNumber,
      email: values.email,
      phone,
      websiteUrl: values.website,
      companyCategories: values.category.map((x) => x.value),
      address: values.address,
      country: values.country?.name,
      zip: values.zip,
      city: values.city,
      companyStrenghts: values.description,
      companySupportDescription: values.descriptionSupportingClub,
      imagesUrls: {
        rectNegative: null,
        rectNormal:
          values?.coverImage?.length > 0 && !(values.coverImage[0] instanceof File)
            ? values.coverImage[0].preview
            : null,
        sqNegative: null,
        sqNormal: values?.image?.length > 0 && !(values.image[0] instanceof File) ? values.image[0].preview : null,
        rectLogoNegative: null,
        rectLogoNormal: null,
      },
    };

    setLoading(true);
    try {
      if (companyID) {
        await Api.company.editCompany(payload, values.id);
        toast.success(`${p('companyUpdated')}!`);
        history.replace('/profile');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('companySavingERROR')}`);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <div>
        <h3 className="page-title">
          {s('edit')} {s('companySmall')}
        </h3>
      </div>
      <Card>
        <CardBody>
          <CompanyProfileForm company={company} onSubmit={handleBusinessInfoSubmit} type="edit" />
        </CardBody>
      </Card>
    </Container>
  );
};

export default CompanyProfileEitPage;
