import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import validateOneTimeCode from './validateOneTimeCode';
import renderField from '../../shared/components/custom/Field';
import { partial } from '../../util/translation/translation';

const p = partial('EnterUsernameForm');
const s = partial('shared');

const EnterUsernameForm = ({ handleSubmit }) => {
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{p('enterYourUsername')}</span>
        <div className="form__form-group-field">
          <Field
            name="username"
            component={renderField}
            type="number"
            placeholder={s('username')}
          />
        </div>
      </div>
      <Button color="primary" className="width-100p">
        {p('sendSMS')}
      </Button>
    </form>
  );
};

EnterUsernameForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'one_time_code_form',
  validateOneTimeCode,
});

export default reduxF(EnterUsernameForm);
