import { getAuth } from 'firebase/auth';
import { setIsAuthenticated, setMe, setCompanies } from '../redux/actions/userActions';
import storage from '../util/storage';
import Api from '../util/api';

async function authenticate(dispatch) {
  try {
    const resMe = await Api.users.getUser();
    dispatch(setMe(resMe));
  } catch (err) {
    dispatch(setIsAuthenticated(false));
  }
}

function afterFirebaseLogin(dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      const resMe = await Api.users.getUser();
      dispatch(setMe(resMe));
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}
function getEmployeeCompanies(dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      const resCompanies = await Api.employees.getEmployeeCompanies();
      dispatch(setIsAuthenticated(true));
      dispatch(setCompanies(resCompanies));
      resolve();
    } catch (err) {
      dispatch(setIsAuthenticated(false));
      reject(err);
    }
  });
}



async function signout(dispatch) {
  await getAuth().signOut();
  storage.removeAuthToken();
  storage.removeCurrentCompany();
  dispatch(setIsAuthenticated(false));
  dispatch(setMe(null));
}


export default {
  authenticate, signout, afterFirebaseLogin, getEmployeeCompanies,
};
