import React from 'react';
import PropTypes from 'prop-types';
import InputEmoji from 'react-input-emoji';

const renderTextAreaField = ({
  input: inputProps, placeholder, type, disabled, meta: { touched, error }, emoji,
}) => {
  const isEmoji = emoji && type === 'text';

  return (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--textarea">
      {isEmoji ? <InputEmoji
        {...inputProps}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      /> : <textarea
        {...inputProps}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      />}
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

renderTextAreaField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

renderTextAreaField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

export default renderTextAreaField;
