import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import EmployeeProfileForm from './EmployeeProfileForm';
import Api from '../util/api';
import Loading from '../shared/components/custom/Loading';
import { partial } from '../util/translation/translation';

const s = partial('shared');
const p = partial('Employee');
const EmployeeProfileEitPage = () => {
  const { employeeID } = useParams();

  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState({});

  const history = useHistory();

  const getEmployeeInformation = async () => {
    setLoading(true);
    try {
      if (employeeID) {
        const resEmployee = await Api.employees.getEmployeeById(employeeID);
        setLoading(false);
        setEmployee(resEmployee);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('employeeFetchError')}'!'`);
    }
  };

  useEffect(() => {
    getEmployeeInformation();
  }, [employeeID]);
  const handleEmployeeEditSubmit = async (values) => {
    const phone = `+${values.countryCode}${values.phoneNumber}`;
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone,
      positionTitle: values.positionTitle,
      visibleCompany: values.visibleCompany,
      visiblePrivate: values.visiblePrivate,
      clubId: values.club.value.clubId,
    };
    setLoading(true);
    try {
      if (employeeID) {
        payload['profileImage'] = (await values?.image?.promise?.('employee', 'sqNormal', employeeID)) || values?.image?.preview.includes('blob')
          ? undefined
          : values?.image?.preview || null;
        await Api.employees.editEmployee(employeeID, payload);
        toast.success(`${p('employeeUpdated')}'!'`);
        history.replace('/employees');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('employeeSavingERROR')}`);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <div>
        <h3 className="page-title">
          {s('edit')} {s('employee')}
        </h3>
      </div>
      <Card>
        <CardBody>
          <EmployeeProfileForm user={employee} onSubmit={handleEmployeeEditSubmit} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default EmployeeProfileEitPage;
