import React from 'react';
import { Col, Row, Button, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderCountryPhone from '../log_in/components/CountryPhone';
import renderField from '../shared/components/custom/Field';
import validateUsername from './findInputValidate';
import { partial } from '../util/translation/translation';

const s = partial('shared');
const e = partial('Employee');

const FindUserForm = ({ handleSubmit, setSearchType, searchType }) => {
  return (
    <div>
      <Col>
        <Row>
          <Col md={6} className="marginBottom-10">
            <p className="mb-2 mt-2">{e('searchForExistingUserInstruction')}</p>
            <Row>
              <Col lg={6} md={12} className="mb-3">
                <Label htmlFor="phone" className={`${searchType === 'phone' && 'bold'}`}>
                  <input id="phone" className="margin-5" type="radio" name="searchType" value={searchType} onChange={() => setSearchType('phone')} defaultChecked />
                  {s('phone')}
                </Label>
                <Label htmlFor="email" className={`${searchType === 'email' && 'bold'}`}>
                  <input id="email" className="margin-5" type="radio" name="searchType" value={searchType} onChange={() => setSearchType('email')} />
                  {s('email')}
                </Label>
              </Col>
            </Row>
          </Col>
        </Row>
        <form className="form" onSubmit={handleSubmit}>
          <Row>
            {(!searchType || searchType === 'email') && (
              <Col lg={10} md={12}>
                <div className="form__form-group">
                  <Field name="email" component={renderField} placeholder={s('email')} />
                </div>
              </Col>
            )}
            {searchType === 'phone' && (
              <Col lg={10} md={12}>
                <div className="form__form-group">
                  <Field name="phone" component={renderCountryPhone} placeholder={s('phone')} />
                </div>
              </Col>
            )}
            <Col lg={2} md={12}>
              <Button color="primary" type="submit" outline size="sm">
                {s('search')}
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </div>
  );
};

FindUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'findUserForm',
  validate: validateUsername,
});

export default reduxF(FindUserForm);
