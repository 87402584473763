import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class TopbarMenuLinks extends PureComponent {
  render() {
    const { title, icon, path, action } = this.props;

    return (
      <Link className="topbar__link" to={path} onClick={action}>
        <span className={`topbar__link-icon sidebar__link-icon icon-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </Link>
    );
  }
}

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  action: PropTypes.func,
};
TopbarMenuLinks.defaultProps = {
  action: undefined,
};
