import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { partial } from './translation/translation';
import { firebaseConfig } from '../_app/firebase';
import isDev from './isDev';
import storage from './storage';

const IDENTITY_TOOLKIT_URL = 'https://identitytoolkit.googleapis.com/v1';
const IDENTITY_RELYING_PARTY_URL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty';
export const getServiceUrl = service => `https://${service}.api${isDev() ? '-dev' : ''}.fangroup.io${service === 'user' ? '/api' : ''}`;
const HEADERS = {
  FEP_CONTEXT: 'fep-context',
  FEP_CONTEXT_NAME: 'fep-context-name',
  FEP_COMPANY_ID: 'fep-company-id',
};
const p = partial('api');
export default class Api {
  static async axios(method, url, auth = true, payload = {}, additionalHeaders = {}) {
    let response;
    const { header, ...remPayload } = payload;
    const headers = { ...header, ...additionalHeaders } || {};

    if (auth) {
      const firebaseAuth = getAuth();
      try {
        const FIRToken = await firebaseAuth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${FIRToken}`;
      } catch {
        headers.Authorization = '';
      }
    }
    try {
      response = await axios({
        url,
        method,
        data: url.includes(getServiceUrl('image')) ? payload : remPayload,
        headers,
        params: method.toLowerCase() === 'get' && remPayload,
      });
    } catch (err) {
      response = {
        ...err.response,
      };
    }

    if (![200, 201].includes(response.status)) {
      if (
        response.status === 500 ||
        (response?.headers?.['content-type'] ?? '').startsWith('text/html')
      ) {
        const err = p('serverOrInternetERROR');
        throw err;
      }
      if (![417, 400].includes(response.status)) {
        let e;
        try {
          e = p(response.data.message);
        } catch {
          e = p(response.status);
        }
        throw e;
      }
      if (response.data.error) {
        if (response.data.error instanceof Array) {
          throw response.data.error.join('\n');
        } else {
          let err = response.data.error;
          if (err.errors) {
            throw err;
          }
          switch (
          err // Translate common errors / exceptions.
          ) {
            case 'token_expired': {
              err = p('expiredLogin');
              break;
            }
            case 'token_invalid': {
              err = p('invalidLogin');
              break;
            }
            case 'token_not_provided': {
              err = p('notLoggedIn');
              break;
            }
            case 'user_not_found':
              err = p('noUserFound');
              break;
            default: {
              err = p('serverOrInternetERROR');
              break;
            }
          }
          throw err;
        }
      } else {
        throw response;
      }
    } else {
      if (response.data) {
        return response.data;
      }
      throw p('noDataFound');
    }
  }

  static getGraphQLClient = async (authenticate = true) => {
    const headers = {};
    if (authenticate) {
      const firebaseAuth = getAuth();
      const FIRToken = await firebaseAuth.currentUser.getIdToken();
      headers.Authorization = `Bearer ${FIRToken}`;
    }
    return new GraphQLClient(`${getServiceUrl('user')}/graphql`, {
      headers,
    });
  };

  static login = {
    doesUserExist: ({ phone, email }) => {
      const queryString = phone
        ? `phoneNumber=${encodeURIComponent(phone)}`
        : `email=${encodeURIComponent(email)}`;
      return Api.axios(
        'get',
        `${getServiceUrl('user')}/doesUserExist?${queryString}`,
      );
    },
  };

  static employees = {
    createEmployee: (payload) => {
      const companyID = storage.getCurrentCompany().id;
      return Api.axios('post', `${getServiceUrl('company')}/employee`, true, {
        ...payload,
        header: { 'fep-company-id': companyID },
      });
    },
    editEmployee: (employeeID, payload) => {
      const companyID = storage.getCurrentCompany().id;
      return Api.axios('put', `${getServiceUrl('company')}/employee/${employeeID}`, true, {
        ...payload,
        header: { 'fep-company-id': companyID },
      });
    },
    getEmployeeById: (employeeID) =>
      Api.axios('get', `${getServiceUrl('company')}/employee/${employeeID}`),
    getEmployeeCompanies: () =>
      Api.axios('get', `${getServiceUrl('company')}/employee/allCompanies`),
    getAllEmployees: (companyID) =>
      Api.axios('get', `${getServiceUrl('company')}/employee/getForCompanyPortal`, true, { header: { 'fep-company-id': companyID } }),
    getAllEmployeeProfiles: () =>
      Api.axios('get', `${getServiceUrl('company')}/employee/allEmployeeProfiles`),
    uploadEmployeeImage: (payload) =>
      Api.axios('post', `${getServiceUrl('image')}`, true, payload),
    removeEmployee: (employeeID, payload) => {
      const companyID = storage.getCurrentCompany().id;
      return Api.axios(
        'DELETE',
        `${getServiceUrl('company')}/employee/${employeeID}`,
        true,
        {
          ...payload,
          header: { 'fep-company-id': companyID },
        },
      );
    },
  };

  static company = {
    getCompany: (ID, clubId) => Api.axios('get', `${getServiceUrl('company')}/company/${ID}`, false, clubId && { header: { 'fep-context': clubId } }),
    editCompany: (payload, companyID, clubId) =>
      Api.axios('put', `${getServiceUrl('company')}/company`, true, {
        ...payload,
        header: { [HEADERS.FEP_COMPANY_ID]: companyID, [HEADERS.FEP_CONTEXT]: clubId },
      }),
    getCompanyClubs: (companyID) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/clubRelation/clubsForCompany`,
        true,
        {
          header: {
            'fep-company-id': companyID,
          },
        },
      ),
    getCompanyCategories: () => Api.axios('get', `${getServiceUrl('company')}/company/companyCategories`),
    getCompanyAllCompanyLevels: (companyId) => Api.axios('get', `${getServiceUrl('company')}/level/levelsByCompany/${companyId}`),
  };

  static club = {
    getClubById: (clubId) => Api.axios('get', `${getServiceUrl('club')}/club/profile/${clubId}`).then((res) => ({ ...res, clubId })),
    getClubConfig: () => Api.axios('get', `${getServiceUrl('club')}/club/teamConfig`),
    getLevels: () => Api.axios('get', `${getServiceUrl('club')}/setting/Level`).then(res => { return res.data; }),
  };

  static users = {
    userSearch: async (condition) => {
      const firebaseAuth = getAuth();
      const FIRToken = await firebaseAuth.currentUser.getIdToken();
      const graphQLClient = new GraphQLClient(`${getServiceUrl('user')}/graphql`, {
        headers: {
          Authorization: `Bearer ${FIRToken}`,
        },
      });
      const query = gql`
        query SearchUser($whereClause: UserWhereUniqueInput!) {
          user(where: $whereClause) {
            userCloudId
            firstname
            lastname
            email
            phoneNumber
          }
        }
      `;
      try {
        const { user } = await graphQLClient.request(query, {
          whereClause: {
            ...condition,
          },
        });
        return user;
      } catch (err) {
        throw p('400');
      }
    },

    getUser: async () => {
      const firebaseAuth = getAuth();
      const { currentUser } = firebaseAuth;
      const userCloudId = currentUser.uid;
      const FIRToken = await currentUser.getIdToken();
      const graphQLClient = new GraphQLClient(`${getServiceUrl('user')}/graphql`, {
        headers: {
          Authorization: `Bearer ${FIRToken}`,
        },
      });
      const query = gql`
        query GetUser($whereClause:  UserWhereUniqueInput!){
          user(where: $whereClause) {
            id
            userCloudId
            firstname
            lastname
            email
            phoneNumber
          }
        }
      `;
      try {
        const { user } = await graphQLClient.request(query, {
          whereClause: {
            userCloudId,
          },
        });
        return user;
      } catch (err) {
        throw p('400');
      }
    },
    getTeamsForUsers: async (userIds) => {
      const graphQLClient = await this.getGraphQLClient();
      const query = gql`
        query GetUserTeams($whereClause:  UserWhereInput!){
          users(where: $whereClause) {
            userCloudId
            teams(where: { deleted: { equals: false } }) {
              team {
                name
              }
            }
          }
        }
      `;
      try {
        const { users } = await graphQLClient.request(query, {
          whereClause: {
            userCloudId: {
              in: userIds,
            },
          },
        });
        const mapped = users.reduce((tot, curr) => ({
          ...tot,
          [curr.userCloudId]: {
          teams: curr.teams.map(({ team: { name } }) => name?.toUpperCase()),
          },
        }), {});
        return mapped;
      } catch (err) {
        throw p('400');
      }
    },
  };

  static authorization = {
    sendSignInWithPhone: (payload) =>
      Api.axios(
        'POST',
        `${IDENTITY_TOOLKIT_URL}/accounts:sendVerificationCode?key=${firebaseConfig.apiKey}`,
        false,
        payload,
      ),
    verifyPhoneCode: (payload) =>
      Api.axios(
        'POST',
        `${IDENTITY_TOOLKIT_URL}/accounts:signInWithPhoneNumber?key=${firebaseConfig.apiKey}`,
        false,
        payload,
      ),
    getCustomToken: (firebaseToken) =>
      Api.axios('GET', `${getServiceUrl('authorization')}/getToken`, false, {
        header: {
          authorization: `Bearer ${firebaseToken}`,
        },
      }),
    signInWithEmailPass: (email, password) =>
      Api.axios(
        'POST',
        `${IDENTITY_RELYING_PARTY_URL}/verifyPassword?key=${firebaseConfig.apiKey}`,
        false,
        {
          email,
          password,
          returnSecureToken: true,
        },
      ),
    sendPasswordResetEmail: async (email) => {
      await Api.axios(
        'POST',
        `${getServiceUrl('user')}/setDeepLinkToApp`,
        false,
        {
          email,
          appName: `company${isDev() ? '-dev' : ''}`,
        },
      );
      const graphQLClient = await this.getGraphQLClient(false);
      const query = gql`
        mutation SendResetCode($email: String!) {
          sendUserPasswordResetLink(email: $email)
        }
      `;
      const { sendUserPasswordResetLink } = await graphQLClient.request(query, {
        email,
      });
      if (!sendUserPasswordResetLink) {
        throw Object.assign(new Error(), { code: 'EMAIL_NOT_SENT' });
      }
      return sendUserPasswordResetLink;
    },
    redeemPasswordReset: async ({ email, token, password }) => {
      const graphQLClient = await this.getGraphQLClient(false);
      const query = gql`
        mutation ResetPass(
          $email: String!
          $token: String!
          $password: String!
        ) {
          redeemUserPasswordResetToken(
            email: $email
            token: $token
            password: $password
          ) {
            code
            message
          }
        }
      `;
      const { redeemUserPasswordResetToken } = await graphQLClient.request(
        query,
        {
          token,
          password,
          email,
        },
      );
      if (redeemUserPasswordResetToken) {
        throw Object.assign(new Error(), {
          code: redeemUserPasswordResetToken.code,
        });
      }
      return true;
    },
  };

  static images = {
    uploadImage: (payload, clubId) => Api.axios('post', `${getServiceUrl('image')}`, true, payload, { [HEADERS.FEP_CONTEXT]: clubId }),
    removeImage: (type, field, id, hash, clubId) => Api.axios('DELETE', `${getServiceUrl('image')}/${hash}?uploadEntityId=${id}&uploadEntityField=${field}&type=${type}`, true, {}, { [HEADERS.FEP_CONTEXT]: clubId }),
  };

  static offers = {
    fetchCategories: async () => {
      const categories = await Api.axios('GET', `${getServiceUrl('offer')}/offer/categories`, true);
      return categories.map(category => ({ label: category.name, value: String(category.id) }));
    },
    fetchLevels: async (fepContext) => {
        const results = await Api.axios('GET', `${getServiceUrl('club')}/setting/Level`, false, { header: { 'fep-context': fepContext } });
        const levels = results.map(level => ({ label: level.value, id: level.id, value: level.id }));
        return levels;
    },
    fetchTiers: async (clubId) => {
      const results = await Api.axios('GET', `${getServiceUrl('tiers')}/tier/getTiers/${clubId}`, true);
      const tiers = results.map(tier => ({ label: tier.name, id: tier.id, value: tier.id }));
      return tiers;
    },
    fetchGroups: async () => {
        const groups = await Api.club.getGroups();
        return groups.map(group => ({ label: group.value, id: group.id, value: group.id }));
    },
    fetchSingleOffer: async (offerId, companyId) => {
        const offer = await Api.axios('GET', `${getServiceUrl('offer')}/offer/${offerId}/company`, true, { header: { 'fep-company-id': companyId } });
        return offer;
    },
    fetchAllOffers: async (companyId, currentPage = 1, numberOfRecords = 10, status = 'active') => {
        const offers = await Api.axios('GET', `${getServiceUrl('offer')}/offer/company?status=${status}&page=${currentPage}&records=${numberOfRecords}`, true, { header: { 'fep-company-id': companyId } });
        return offers;
    },
    filterOffersByCategory: async (category, companyId, currentPage = 1, numberOfRecords = 10, status = 'active') => {
        const offers = await Api.axios('GET', `${getServiceUrl('offer')}/offer/company?status=${status}&category=${category}&page=${currentPage}&records=${numberOfRecords}`, true, { header: { 'fep-company-id': companyId } });
        return offers;
    },
    createOffer: async (payload, companyId) => {
        const offer = await Api.axios('POST', `${getServiceUrl('offer')}/offer/company`, true, { header: { 'fep-company-id': companyId }, ...payload });
        return offer;
    },
    updateOfferById: async (offerId, payload, companyId) => {
        const offer = await Api.axios('PUT', `${getServiceUrl('offer')}/offer/${offerId}/company`, true, { header: { 'fep-company-id': companyId }, ...payload });
        return offer;
    },
    deleteOfferById: async (offerId, companyId) => {
        const offer = await Api.axios('DELETE', `${getServiceUrl('offer')}/offer/${offerId}/company`, true, { header: { 'fep-company-id': companyId } });
        return offer;
    },
  };
}
