/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useRecoilValue } from 'recoil';
import { connect } from 'react-redux';
import renderField from '../shared/components/custom/Field';
import renderSelectField from '../shared/components/form/Select';
import { partial } from '../util/translation/translation';
import validateDetails from './validateDetails';
import userLanguageState from '../../recoil/selector/selector';
import { constructImageData, getCountryList, getImageHash } from '../util/functions';
import renderCheckBoxField from '../shared/components/form/CheckBox';
import TooltipWithIcon from '../shared/components/custom/TooltipWithIcon';
import coverToolTipImg from '../../assets/header-img.png';
import partnerToolTipImg from '../../assets/partner-img.png';
import coverToolTipFullImg from '../../assets/full-header-img.png';
import partnerToolTipFullImg from '../../assets/full-partner-img.png';
import renderDropZoneField from '../shared/components/form/DropZoneMS';

function SponsorMaterialForm({ handleSubmit, initialize, images, goBack, formValues }) {
    const s = partial('shared');
    const p = partial('SponsorForm');
    const userLanguage = useRecoilValue(userLanguageState);
    const countryOptions = getCountryList(userLanguage);
    useEffect(() => {
      if (images) {
        initialize({
            companyImage: constructImageData(images, 'companyImage', 'company-logo'),
            appSponsorImage: constructImageData(images, 'appSponsorImage', 'app-sponsor-logo'),
            appTicketImage: constructImageData(images, 'appTicketImage', 'app-ticket-image'),
            coverImage: constructImageData(images, 'coverImage', 'cover-image'),
            playerImage: constructImageData(images, 'playerImage', 'app-player-image'),
            printImage: constructImageData(images, 'printImage', 'app-print-image'),
          });
      }
    }, [images]);
    return (
      <form onSubmit={handleSubmit} className="form">
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('companyImageInApp')}
              <TooltipWithIcon
                text={partnerToolTipImg}
                id="profileImage"
                fullImageUrl={partnerToolTipFullImg}
                isImageUrl
              />
              <p>
                {p('companyImageInAppInfo')}
                <br />
                {p('recommendedImgSizeCompanyLogo')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="companyImage" component={renderDropZoneField} crop="PROFILE_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="w-100">
            {formValues.companyImage && !formValues.companyImage?.remove && <img className="company-logo" src={formValues.companyImage.croppedImage || formValues.companyImage?.preview} alt="" />}
          </div>
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('companyCoverImageInApp')}
              <TooltipWithIcon text={coverToolTipImg} id="coverImage" fullImageUrl={coverToolTipFullImg} isImageUrl />
              <p>
                {p('companyCoverImageInAppInfo')}
                <br />
                {p('recommendedImgSizeCompanyCover')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="coverImage" component={renderDropZoneField} crop="BANNER_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.coverImage && !formValues.coverImage?.remove && <img className="company-cover" src={formValues.coverImage.croppedImage || formValues.coverImage?.preview} alt="" />}
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('matchSponsor')}
              <p>
                {p('imageOverTicketValidation')}
                <br />
                {p('recommendedImgSizeMatchSponsor')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="appTicketImage" component={renderDropZoneField} crop="MATCHSPONSOR_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.appTicketImage && !formValues.appTicketImage?.remove && <img className="sponsor-app sponsor-app-ticket" src={formValues.appTicketImage.croppedImage || formValues.appTicketImage?.preview} alt="" />}
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('playerCard')}
              <p>
                {p('playerImage')}
                <br />
                {p('recommendedImgSizePlayerImage')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field
              name="playerImage"
              component={renderDropZoneField}
              style={{ width: 'auto', height: 'auto' }}
              crop="PLAYERCARD_CROP"
            />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.playerImage && !formValues.playerImage?.remove && <img className="sponsor-player bg-dark" src={formValues.playerImage.croppedImage || formValues.playerImage?.preview} alt="" />}
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('printedPaperTicket')}
              <p>
                {p('boxOffice&WebSalesImage')}
                <br />
                {p('recommendedImgSizePrintedPaperTicket')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field
              name="printImage"
              component={renderDropZoneField}
              style={{ width: 'auto', height: 'auto' }}
              crop="PRINTEDPAPERTICKET_CROP"
            />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.printImage && !formValues.printImage?.remove && <img className="sponsor-print" src={formValues.printImage.croppedImage || formValues.printImage?.preview} alt="" />}
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('appSponsorInApp')}
              <p>
                {p('appSponsorLogoInAppInfo')}
                <br />
                {p('recommendedImgSizeAppSponsorLogo')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="appSponsorImage" component={renderDropZoneField} crop="APP_SPONSOR_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="w-100">
            {formValues.appSponsorImage && !formValues.appSponsorImage?.remove && <img className="app-sponsor-logo" src={formValues.appSponsorImage.croppedImage || formValues.appSponsorImage?.preview} alt="" />}
          </div>
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col>
          <Button type="submit" color="primary">{s('save')}</Button>
          <Button color="secondary" onClick={goBack}>{s('cancel')}</Button>
        </Col>
      </form>
    );
}
SponsorMaterialForm.defaultProps = {
    formValues: {},
  };
const reduxF = reduxForm({
    form: 'sponsorMaterialForm',
});
const mapStateToProps = (state) => ({
    formValues: getFormValues('sponsorMaterialForm')(state),
});
export default connect(mapStateToProps)(reduxF(SponsorMaterialForm));
