import { SET_COMPANY_CLUBS } from '../actions/clubActions';

const initialState = {
  clubs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_CLUBS:
      return {
        ...state,
        clubs: action.data,
      };
    default:
      return state;
  }
}
