import React, { useEffect, useState, useCallback } from 'react';
import { Container, Col, Card, CardBody, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PhoneIcon from 'mdi-react/PhoneIcon';
import MailIcon from 'mdi-react/EmailIcon';
import SiteIcon from 'mdi-react/WebIcon';
import Skeleton from 'react-loading-skeleton';
import Loading from '../shared/components/custom/Loading';
import BusinessCard from '../employee/BusinessCard';
import Api from '../util/api';
import t, { partial } from '../util/translation/translation';
// import placeholderImg from '../../assets/1000x900.png';
import storage from '../util/storage';

const p = partial('companyProfile');
const CompanyProfilePage = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentCompany, setCurrentCompany] = useState();
  const companyID = storage.getCurrentCompany().id;

  const history = useHistory();

  const getCompanyInfo = useCallback(async () => {
    setLoading(true);
    try {
      const resCompany = await Api.company.getCompany(companyID);
      setCurrentCompany(resCompany);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(t('companyFetchError'));
    }
  }, [companyID]);

  const getAllEmployees = useCallback(async () => {
    setLoading(true);
    try {
      if (currentCompany?.id) {
        const resEmployees = await Api.employees.getAllEmployees(currentCompany.id);
        setEmployees(resEmployees);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(t('Employee.employeeFetchError'));
    }
  }, [currentCompany]);

  useEffect(() => {
    getCompanyInfo();
  }, [getCompanyInfo]);

  useEffect(() => {
    getAllEmployees();
  }, [getAllEmployees]);

  const imgSrc = currentCompany?.imagesUrls?.sqNormal;
  const coverImgSrc = currentCompany?.imagesUrls?.rectNormal;
  return (
    <Container>
      <Loading loading={loading} />
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="profile__card font-16">
            {coverImgSrc ? (
              <div className="container__cover">
                {loading && <Skeleton height={400} />}
                {!loading && <img src={coverImgSrc} alt="Företagslogo" />}
              </div>
            ) : (
              <div className="container__cover_placeholder">{currentCompany?.name}</div>
            )}

            <div className="company__profile__information">
              {imgSrc ? (
                <div className="company__profile__avatar">
                  {loading && <Skeleton height={500} />}
                  {!loading && <img src={imgSrc} alt="Företagslogo" />}
                </div>
              ) : (
                <div className="company__profile__avatar_placeholder">{currentCompany?.name[0]?.toUpperCase()}</div>
              )}

              <div className="profile__data">
                <p className="profile__name">{currentCompany?.name}</p>
                <p className="profile__work">{currentCompany?.companyCategory}</p>
                {currentCompany?.email && (
                  <p className="profile__contact">
                    <MailIcon size={14} /> {currentCompany?.email}
                  </p>
                )}
                {currentCompany?.phone && (
                  <p className="profile__contact">
                    <PhoneIcon size={14} /> {currentCompany?.phone}
                  </p>
                )}
                {currentCompany?.websiteUrl && (
                  <p className="profile__contact">
                    <SiteIcon size={14} /> {currentCompany?.websiteUrl}
                  </p>
                )}
              </div>
              <div className="profile_button_container">
                <Button
                  className="marginTop-20"
                  color="primary"
                  onClick={() => history.replace(`/profile/${currentCompany.id}`)}
                >
                  <p>{p('editProfile')}</p>
                </Button>
              </div>
            </div>
            <div className="p-lr-200">
              <div className="profile_description_label">{p('companyDescription')}</div>
              <p className="profile_description">{currentCompany?.companyStrenghts}</p>
              <div className="profile_description_label">{p('whyWeSupport')}</div>
              <p className="profile_description">{currentCompany?.companySupportDescription}</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <div className="profile_heading">{p('companyContact')}</div>
      {employees.map((employee) => {
        return <BusinessCard contactDetail={employee} key={`businessCard-${employee.id}`} />;
      })}
    </Container>
  );
};

export default withRouter(
  connect((state) => ({
    companies: state.auth.companies,
  }))(CompanyProfilePage),
);
